import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {AppContext} from "../context/AppContext";
import parse from "html-react-parser";

const Footer = () => {
    const {data} = useContext(AppContext);

    return (
        <div className="container-fluid bg-light footer text-secondary mt-5">
            <div className="row px-xl-5 py-4 top-footer">
                <div className="col-lg-6 col-sm-12">
                    <img src={window.location.origin + "/img/logo.png"} alt="" />
                </div>
                <div className="col-lg-6 col-sm-12 d-flex align-items-center justify-content-between">
                    <h4 className="m-0">Need Any Help?</h4>
                    <Link to="/products">Get Started</Link>
                    <Link to="/pages/terms_and_conditions">Terms &amp; Conditions</Link>
                </div>
            </div>
            <div className="row px-xl-5 pt-xl-5">
                <div className="col-lg-6 col-md-12 mb-5 pr-3 pr-xl-5">
                    <h4 className="mb-4">About Company</h4>
                    {
                        data?.site_data ?
                        parse(data?.site_data?.about_us) : ''
                    }
                </div>
                <div className="col-lg-6 col-md-12 mb-5 pr-3 pr-xl-5">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <h4 className="mb-4">Quick Links</h4>
                            <ul className="footer-links">
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/pages/about_us">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/pages/refund_policy">Return &amp; Refund</Link>
                                </li>
                                <li>
                                    <Link to="">FAQ's</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <h4 className="mb-4">Contact Information</h4>
                            <ul className="footer-links">
                                <li>{data?.site_data?.contact_number}</li>
                                <li>{data?.site_data?.email}</li>
                                <li>www.billubhaistore.com</li>
                            </ul>
                            {/*<div className="social-icons">*/}
                            {/*    <li>*/}
                            {/*        <a href={data?.site_data?.linkedin_url}>*/}
                            {/*            */}
                            {/*            <span className="fab fa-linkedin" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <a href={data?.site_data?.contact_number}>*/}
                            {/*            <span className="fab fa-pinterest" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <a href="">*/}
                            {/*            <span className="fab fa-facebook-f" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <a href="">*/}
                            {/*            <span className="fab fa-instagram" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <a href="">*/}
                            {/*            <span className="fab fa-twitter" />*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;