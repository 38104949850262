import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getMyWishList, getUserAddresses, getUserWithToken} from "../../requests";

export const getUserDetail = createAsyncThunk(
    'userDetail',
    async (data) => {
        try {
            let response = await getUserWithToken(data);
            return response.data.user;
        } catch (e) {
            console.log("Something went wrong")
        }
    }
);

export const getAddresses = createAsyncThunk(
    'userAddresses',
    async (data) => {
        try {
            let response = await getUserAddresses(data);
            return response.data.addresses;
        } catch (e) {
            console.log("Something went wrong")
        }
    }
);

export const getWishList = createAsyncThunk(
    'userWishList',
    async (data) => {
        try {
            let response = await getMyWishList(data);
            return response.data.wishlist;
        } catch (e) {
            console.log("Something went wrong")
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: [],
        loading: null,
        addresses: [],
        active_address: '',
        wishlist: []
    },
    reducers: {
        addAddressToObject: ((state, action) => {
            state.addresses.push(action.payload);
            state.active_address = action.payload;
        }),
        addItemToWishList : ((state, action) => {
            state.wishlist.push(action.payload)
        }),
        removeAddress: ((state, action) => {
            state.addresses = state.addresses.filter((address) => address.id !== action.payload)
        })
    },
    extraReducers: {
        [getUserDetail.pending]: (state) => {
            state.loading = true;
        },
        [getUserDetail.fulfilled]: (state, action) => {
            state.loading = false;
            state.user = action.payload;
        },
        [getUserDetail.rejected]: (state) => {
            state.loading = false;
        },
        [getAddresses.pending]: (state) => {
            state.loading = true;
        },
        [getAddresses.fulfilled]: (state, action) => {
            state.loading = false;
            let addresses = action.payload;
            let active_address = addresses.filter((address) => {
                return address.active;
            });

            state.addresses = addresses;
            if (addresses.length) {
                state.active_address = active_address[0];
            }
        },
        [getAddresses.rejected]: (state) => {
            state.loading = false;
        },
        [getWishList.pending]: (state) => {
            state.loading = true;
        },
        [getWishList.fulfilled]: (state, action) => {
            state.loading = false;
            state.wishlist = action.payload;
        },
        [getWishList.rejected]: (state) => {
            state.loading = false;
        }
    }
});

export const {addAddressToObject, addItemToWishList, removeAddress} = userSlice.actions;
export default userSlice.reducer;