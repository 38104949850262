import React from "react";
import {Link} from "react-router-dom";
import {Carousel} from "react-responsive-carousel";

const Banners = (props) => {
    const {banners} = props;

    return (
        <Carousel showArrows={false} showThumbs={false} showStatus={false}>
            {
                typeof banners !== "undefined" &&
                banners.map((banner, index) => {
                    return (
                        <Link to="/products" key={index}>
                            <div className="position-relative">
                                <img
                                    className="w-100 h-100"
                                    src={process.env.REACT_APP_IMAGE_PATH + banner?.path + banner?.title}
                                />
                            </div>
                        </Link>
                    )
                })
            }
        </Carousel>
    )
};

export default Banners;