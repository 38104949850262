import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMotorcycle, faCheckCircle, faSearch} from "@fortawesome/free-solid-svg-icons";
import {getCategoryProducts, getProducts} from "../../requests";
import {Link, useSearchParams} from "react-router-dom";
import RangeSlider from "react-range-slider-input";

const Filter = (props) => {
    const {
        categories,
        brands,
        handleFilters,
        currentPage,
        setLoader,
        setProducts,
        setTotalProducts,
        setTotalPages,
        setCategories,
        setBrands,
        minPrice,
        maxPrice,
        setMinPrice,
        setMaxPrice
    } = props;
    const [brandsArr, setBrandsArr] = useState(brands);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [price, setPrice] = useState([minPrice, maxPrice]);
    const searchParams = useSearchParams();

    const filterProducts = () => {
        setLoader(true);
        let limit = 12;
        // let query = searchParams.get('q');
        getProducts(currentPage, '', selectedBrand, price[0], price[1]).then((res) => {
            setProducts(res.data.data.products);
            setTotalProducts(res.data.data.total_products);
            setTotalPages(Math.ceil(res.data.data.total_products / limit).toFixed(0));
            setCategories(res.data.data.categories);
            setBrands(res.data.data.brands);
            setMinPrice(res.data.data.minPrice);
            setMaxPrice(res.data.data.maxPrice);
            setLoader(false);
        });
    };

    const filterBrand = (e) => {
        let filtered_brands = brands.filter((brand) => {
            if (brand.title.includes(e)) {
                return brand;
            }
        });

        setBrandsArr(filtered_brands);
    };

    const resetFilters = () => {
        setLoader(true);
        let limit = 12;
        // let query = searchParams.get('q');
        getProducts(currentPage, '').then((res) => {
            setProducts(res.data.data.products);
            setTotalProducts(res.data.data.total_products);
            setTotalPages(Math.ceil(res.data.data.total_products / limit).toFixed(0));
            setCategories(res.data.data.categories);
            setBrands(res.data.data.brands);
            setMinPrice(res.data.data.minPrice);
            setMaxPrice(res.data.data.maxPrice);
            setLoader(false);
        });
    }

    return (
        <>
            <div className="filters shadow">
                <div className="filter-section">
                    <button className="btn btn-info btn-block" onClick={() => resetFilters()}>Reset Filters</button>
                </div>
                <hr/>
                <div className="filter-section">
                    <h5>Categories</h5>
                    <ul className="common-filters">
                        {
                            categories.map((category, index) => {
                                return (
                                    category.sub_categories.length ? (
                                        <>
                                            <h5>{category.category}</h5>
                                            {
                                                category.sub_categories.map((category, index1) => {
                                                    return (
                                                        category.sub_categories.length ? (
                                                            <>
                                                                <h5 key={index1}
                                                                    className="ml-3">{category.category}</h5>
                                                                {
                                                                    category.sub_categories.map((category, index2) => {
                                                                        return (
                                                                            <li className="ml-5" key={index}>
                                                                                <a href={`${window.location.origin}/categories/${category.slug}`}>
                                                                                    {/*<FontAwesomeIcon icon={faCheckCircle} className="text-info" /> */}
                                                                                    {category?.category}
                                                                                    <span>({category?.products.length})</span>
                                                                                </a>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        ) : (
                                                            <li key={index}>
                                                                <a href={`categories/${category.slug}`}>
                                                                    {/*<FontAwesomeIcon icon={faCheckCircle} className="text-info" /> */}
                                                                    {category?.category}
                                                                    <span>({category?.products.length})</span>
                                                                </a>
                                                            </li>
                                                        )
                                                    )
                                                })
                                            }
                                        </>
                                    ) : (
                                        <li key={index}>
                                            <a href={`categories/${category.slug}`}>
                                                {/*<FontAwesomeIcon icon={faCheckCircle} className="text-info" /> */}
                                                {category?.category}
                                                <span>({category?.products.length})</span>
                                            </a>
                                        </li>
                                    )
                                )
                            })
                        }
                    </ul>
                </div>
                <hr/>
                <div className="filter-section">
                    <h5>Brand</h5>
                    <div className="filter_input">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faSearch}/>
                              </span>
                            </div>
                            <input
                                type="text"
                                className="form-control bg-transparent"
                                placeholder="Filter Brands"
                                onChange={(e) => filterBrand(e.target.value)}
                            />
                        </div>
                    </div>
                    <ul className="common-filters">
                        {
                            brandsArr.map((brand, index) => {
                                return (
                                    <li onClick={() => handleFilters('brand', brand?.id)} key={index}>
                                        {
                                            selectedBrand === brand.id ? (
                                                <>
                                                    <FontAwesomeIcon icon={faCheckCircle} className="text-info"/>
                                                    {" "}
                                                </>
                                            ) : ''
                                        }
                                        <a href="javascript:void(0);">
                                            {brand?.title} <span>({brand?.products.length})</span>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <hr/>
                <div className="filter-section">
                    <h5>Shop by Price</h5>
                    <div className="my-4">
                        <RangeSlider
                            min={minPrice}
                            max={maxPrice}
                            defaultValue={[minPrice, maxPrice]}
                            onInput={(e) => {
                                setPrice(e)
                            }}
                        />
                    </div>
                    <div className="price-blocks">
                        <div className="price-input">
                            <strong>PKR</strong>
                            <input type="text" value={price[0]}/>
                        </div>
                        <span>to</span>
                        <div className="price-input">
                            <strong>PKR</strong>
                            <input type="text" value={price[1]}/>
                        </div>
                        <a href="javascript:void(0);" onClick={() => filterProducts()} className="go-btn text-info">GO</a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Filter;