import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "./common/Sidebar";
import {useSelector} from "react-redux";
import MobileNavbar from "./common/MobileNavbar";

const WishList = () => {
    const user_detail = useSelector((state) => state.userRedux);
    const wishList = user_detail.wishlist;

    return (
        <Container fluid>
            <div className="dashboard-wrapper">
                <div className="my-4 d-lg-none d-md-block">
                    <MobileNavbar/>
                </div>
                <Row>
                    <Sidebar activeTab='my-wishlist'/>
                    <Col lg={8} sm={12}>
                        <h5><strong>My Wishlist</strong></h5>
                        <div className="card">
                            <div className="card-body">
                                <div className="checkout-items">
                                    {
                                        wishList.map((item, index) => {
                                           return (
                                               <div className="item" key={index}>
                                                   <img
                                                       src={process.env.REACT_APP_IMAGE_PATH + item.product.default_image}
                                                       alt=""/>
                                                   <div>
                                                       <h5>{item.product.product_title}</h5>
                                                       <p>Rs. {item.product.price}</p>
                                                   </div>
                                               </div>
                                           )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default WishList;