import React, {useContext, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "./common/Sidebar";
import {AppContext} from "../../context/AppContext";
import {useSelector} from "react-redux";
import Barcode from "react-barcode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import MobileNavbar from "./common/MobileNavbar";

const BilluClub = () => {
    const {data} = useContext(AppContext);
    const user_detail = useSelector((state) => state.userRedux.user);

    return (
        <>
            <Container fluid>
                <div className="dashboard-wrapper">
                    <div className="my-4 d-lg-none d-md-block">
                        <MobileNavbar/>
                    </div>
                    <Row>
                        <Sidebar activeTab="billu-club"/>
                        <Col lg={8} sm={12}>
                            <div className="dashboard-content">
                                <h5><strong>BilluCLUB</strong></h5>
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <Row>
                                            <Col sm={6}>
                                                <div className="card bg-info border-radius-5">
                                                    <div className="card-body">
                                                        <div className="billu-card">
                                                            <h6>BilluCLUB <span className="float-right">Earn more everyday</span>
                                                            </h6>
                                                            <p>{user_detail?.billu_points} <span
                                                                className="float-right">Points</span></p>
                                                            <p>{user_detail?.billu_points * data?.site_data?.amount_to_be_used_on_points}
                                                                <span className="float-right">RS</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <Barcode displayValue={false} value={user_detail?.billu_card_number} />
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div className="billu-card-detail">
                                                    <h5><strong>Card No: {user_detail?.billu_card_number}</strong></h5>
                                                    <h6><strong><FontAwesomeIcon icon={faAt} /></strong> {user_detail?.email}</h6>
                                                    <Link className="text-info" to="/billu-points">View History</Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}

export default BilluClub;