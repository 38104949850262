import axios from "axios";
import CryptoJS from "crypto-js";

export async function getSiteData() {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/site-setting', {headers: {apikey: process.env.REACT_APP_KEY}});
}

export async function getHomeFeeds() {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/homeFeeds', {headers: {apikey: process.env.REACT_APP_KEY}});
}

export async function getProducts(page, search, brand = "", minPrice = "", maxPrice = "", orderBy = "") {
    let query = '?page_number=' + page;

    if (typeof search !== "undefined" && search) {
        query = query + '&q=' + search;
    }

    query = query + '&brand=' + brand + '&min_price=' + minPrice + '&max_price=' + maxPrice + "&order=" + orderBy;

    return axios.get(process.env.REACT_APP_BACKEND_URL + '/getProducts' + query, {headers: {apikey: process.env.REACT_APP_KEY}});
}

export async function getCategoryProducts(page, category, brand = "", minPrice = "", maxPrice = "") {
    let query = '?page_number=' + page + '&category=' + category + '&brand=' + brand + '&min_price=' + minPrice + '&max_price=' + maxPrice;
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/getCategoryProducts' + query, {headers: {apikey: process.env.REACT_APP_KEY}});
}

export async function getProductDetail(slug) {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/product/' + slug, {headers: {apikey: process.env.REACT_APP_KEY}});
}

export async function getCities() {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/cities-options', {headers: {apikey: process.env.REACT_APP_KEY}});
}

export async function UserRegister(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/register', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature
        }
    })
}

export async function verifyUser(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/verify-token', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature
        }
    })
}

export async function userLogin(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/sign-in', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature
        }
    })
}

export async function getUserWithToken(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/get-user-by-token', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + payload.token
        }
    })
}

export async function getUserAddresses(payload) {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/get-addresses', {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            Authorization: 'Bearer ' + payload.token
        }
    })
}

export async function addUserAddress(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/save-address', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function saveOrder(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/save-cart-session', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function applyPromoCode(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/apply-code', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function getUserPointsHistory(page) {
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.get(process.env.REACT_APP_BACKEND_URL + '/get-billu-points-history?page=' + page, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function updateProfile(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/update-profile', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function getMyOrders(limit) {
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.get(process.env.REACT_APP_BACKEND_URL + '/my-orders?limit=' + limit, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function getMyReturns(limit) {
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.get(process.env.REACT_APP_BACKEND_URL + '/my-returns?limit=' + limit, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function returnOrderRequest(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/return-order', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function getMyWishList(payload) {
    return axios.get(process.env.REACT_APP_BACKEND_URL + '/my-wishlist', {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            Authorization: 'Bearer ' + payload.token
        }
    })
}

export async function addToWishlist(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/add-to-wishlist', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}


export async function saveReview(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/save-review', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function getVisitedProducts(payload) {
    let apisignature = CryptoJS.HmacSHA256(JSON.stringify(payload), process.env.REACT_APP_HMAC_PRIVATE_KEY).toString();
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.post(process.env.REACT_APP_BACKEND_URL + '/get-recent-products', payload, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            verify_request: 'always',
            bodySignature: apisignature,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function deleteMyAddress(id) {
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.get(process.env.REACT_APP_BACKEND_URL + '/delete-address/' + id, {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}

export async function logout() {
    let secure_pass = localStorage.getItem('__site_pass');
    secure_pass = JSON.parse(secure_pass);

    return axios.get(process.env.REACT_APP_BACKEND_URL + '/logout', {
        headers: {
            apikey: process.env.REACT_APP_KEY,
            Authorization: 'Bearer ' + secure_pass.token
        }
    })
}