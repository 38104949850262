import React, {useEffect} from "react";
import Loader from "../components/Loader";
import {verifyUser} from "../requests";
import {useNavigate} from "react-router-dom";

const Verification = () => {
    const history = useNavigate();

    const verify = async (payload) => {
        await verifyUser(payload).then((resonse) => {
            if (resonse.data.status_code === 200) {
                history('/?login=1');
            }
        })
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        verify(params)
    });

    return (
        <Loader/>
    )
};

export default Verification;