import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPen, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {decrementQuantity, incrementQuantity, removeItem, setCart} from "../../features/cart/cartSlice";
import {useDispatch} from "react-redux";
import {AppContext} from "../../context/AppContext";
import {getVisitedProducts} from "../../requests";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProductSlider from "../../components/ProductSlider";

const CartItems = () => {
    const cart_items = useSelector((state) => state.cart.items);
    const dispatch = useDispatch();
    const {data} = useContext(AppContext);
    const [products, setProducts] = useState([]);

    const getRecentProducts = async () => {
      let visitedProducts = localStorage.getItem('visited_products');
      visitedProducts = JSON.parse(visitedProducts);
      if (visitedProducts) {
          getVisitedProducts(visitedProducts).then((response) => {
              setProducts(response.data.data.products);
          });
      }
    }

    useEffect(() => {
        if (products.length === 0) {
            getRecentProducts();
        }
    })

    return (
        <div className="col-lg-8 col-sm-12 mt-4">
            <div className="card">
                <div className="card-body">
                    <h4>Cart Summary</h4>
                    {/*<p>*/}
                    {/*    Your deliver date was chosen automatically based on the*/}
                    {/*    availability of the item in your cart. Click{" "}*/}
                    {/*    <FontAwesomeIcon icon={faPen}/> in the delivery week title to*/}
                    {/*    change the week your items are delivered.*/}
                    {/*</p>*/}
                </div>
            </div>
            <div className="card mt-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6">
                            {
                                data?.site_data?.delivery_time ? (
                                    <h5>
                                        Delivery in { data?.site_data?.delivery_time } minutes
                                    </h5>
                                ) : ''
                            }
                        </div>
                        <div className="col-6 text-right">
                            <strong>{cart_items.length} items</strong>
                        </div>
                        <div className="col-12">
                            <div className="cart-items">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Subtotal</th>
                                        {/*<th/>*/}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        cart_items.length ?
                                            cart_items.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="cart-item">
                                                                <img
                                                                    src={process.env.REACT_APP_IMAGE_PATH + '/' + item.image}
                                                                    alt=""/>
                                                                <h5>{item.title}</h5>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>RS. {item.price.toFixed(2)}</p>
                                                        </td>
                                                        <td>
                                                            <div className="qty-control">
                                                                <button className="minus" onClick={() => {
                                                                    dispatch(decrementQuantity(item.id));
                                                                    dispatch(setCart());
                                                                }}>
                                                                    <FontAwesomeIcon icon={faMinus}/>
                                                                </button>
                                                                <input type="text" value={item.qty} readOnly={true}/>
                                                                <button className="plus" onClick={() => {
                                                                    dispatch(incrementQuantity(item.id))
                                                                }}>
                                                                    <FontAwesomeIcon icon={faPlus}/>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p>RS. {(item.price * item.qty).toFixed(2)}</p>
                                                        </td>
                                                        {/*<td>*/}
                                                        {/*    <span className="remove-item" onClick={() => {dispatch(removeItem(item.id))}}>*/}
                                                        {/*        <FontAwesomeIcon icon={faTimes}/>*/}
                                                        {/*    </span>*/}
                                                        {/*</td>*/}
                                                    </tr>
                                                )
                                            }) :
                                            (
                                                <>
                                                    <tr>
                                                        <td colSpan={5} className="text-center">
                                                            <div className="my-5">
                                                                <h4>No items in cart</h4>
                                                                <Link className="cart-btn mb-3" to="/products">Continue
                                                                    to shopping</Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {
                        products.length > 0 ? (
                            <div className="similar-products-section mt-5" id="discount-deals">
                                <Row className="px-5">
                                    <Col sm={12}>
                                        <h3 className="underline-heading">
                                            <span>Recent</span> Viewed Products
                                        </h3>
                                    </Col>
                                    {
                                        products.length ? (
                                            <Col sm={12} className="mt-3">
                                                <ProductSlider products={products} regular={false} recentItems={true} />
                                            </Col>
                                        ) : ''
                                    }
                                </Row>
                            </div>
                        ) : ''
                    }
                </div>
            </div>
        </div>
    )
};

export default CartItems;