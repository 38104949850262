import React, {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "./common/Sidebar";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {deleteMyAddress} from "../../requests";
import {toast} from "react-toastify";
import {removeAddress} from "../../features/user/userSlice";
import Loader from "../../components/Loader";
import MobileNavbar from "./common/MobileNavbar";

const MyAddresses = () => {
    const addresses = useSelector((state) => state.userRedux.addresses);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    const deleteAddress = async (id) => {
        setLoader(true);
        await deleteMyAddress(id).then((response) => {
            dispatch(removeAddress(id));
            toast.success(response.data.message, {
                theme: "light",
                position: "top-right"
            })
            setLoader(false);
        }).catch((error) => {
            toast.error(error.response.data.message, {
                theme: "light",
                position: "top-right"
            })
            setLoader(false);
        })
    }

    return (
        loader ?
            <Loader/>
            : (
                <Container fluid>
                    <div className="dashboard-wrapper">
                        <div className="my-4 d-lg-none d-md-block">
                            <MobileNavbar/>
                        </div>
                        <Row>
                            <Sidebar activeTab='my-addresses'/>
                            <Col lg={8} sm={12}>
                                <h5><strong>My Wishlist</strong></h5>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="addresses-wrapper">
                                            <div className="address-item">
                                                <div className="add-address-wrap">
                                            <span>
                                                <FontAwesomeIcon icon={faPlus}/>
                                            </span>
                                                    <h5>Add New Address</h5>
                                                </div>
                                            </div>
                                            {
                                                addresses.map((address, index) => {
                                                    return (
                                                        address.labels ? (
                                                            <div className="address-item" key={index}>
                                                                <div className="address">
                                                                    <h5><strong>{address.labels.toUpperCase()}&nbsp;
                                                                        {
                                                                            address.active ? (
                                                                                "(Default)"
                                                                            ) : ''
                                                                        }
                                                                    </strong></h5>
                                                                    <p>{address.address}</p>
                                                                    <span className="delete text-info"
                                                                          onClick={() => deleteAddress(address.id)}>Delete</span>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            )
    )
};

export default MyAddresses;