import React from "react";
import {calculatePrice} from "../../helpers/productsHelper";
import {Link} from "react-router-dom";

const DiscountItemCard = (props) => {
    const {item} = props;

    return (
        <>
            <Link to="/products">
                <div className="discount-product-section">
                    <div className="product-img">
                        <img src={process.env.REACT_APP_IMAGE_PATH + item.default_image}/>
                    </div>
                    <div className="product-detail">
                        <h4>{item.product_title}</h4>
                        <p>{item?.unit_value} {item?.unit?.prefix}</p>
                        <h4>
                            Rs. {calculatePrice(item)}{" "}
                            <span className="float-right badge badge-primary">{item.discount_value}{item.discount_type === 'value' ? ' Rs' : '%'} Off</span>
                        </h4>
                    </div>
                </div>
            </Link>
        </>
    )
};

export default DiscountItemCard;