import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import Sidebar from "./common/Sidebar";
import {Link} from "react-router-dom";
import Loader from "../../components/Loader";
import {toast} from "react-toastify";
import OrderCard from "./common/OrderCard";
import {getMyReturns} from "../../requests";
import MobileNavbar from "./common/MobileNavbar";

const MyReturns = () => {
    const [orders, setOrders] = useState([]);
    const [loader, setLoader] = useState(true);
    const [limit, setLimit] = useState(1);
    const [totalOrders, setTotalOrders] = useState(0);

    const getOrders = async (limit) => {
        await getMyReturns(limit).then((response) => {
            setOrders(response.data.data.orders);
            setTotalOrders(response.data.data.count);
        }).catch((error) => {
            toast.error('Something went wrong', {
                theme: "light",
                position: "top-right"
            });
        });
        setLoader(false);
    };

    const handleOrders = async () => {
        const lmt = limit + 4;
        await getOrders(lmt);
        setLimit(lmt);
    }

    useEffect(() => {
        if (orders.length === 0) {
            getOrders(1);
        }
    }, [orders]);

    return (

        loader ? (
            <Loader/>
        ) : (
            <Container fluid>
                <div className="dashboard-wrapper">
                    <div className="my-4 d-lg-none d-md-block">
                        <MobileNavbar/>
                    </div>
                    <Row>
                        <Sidebar activeTab="my-returns" />
                        <Col lg={8} sm={12}>
                            <h5><strong>My Returns</strong></h5>
                            {
                                orders.length ?
                                    orders.map((order, index) => {
                                        return (
                                            <OrderCard order={order} key={index}/>
                                        )
                                    })
                                    : (
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="no-item-wrapper">
                                                    <div className="border-1">
                                                        <div className="border-2">
                                                            <img src={window.location.origin + "/img/no-item.png"} alt=""/>
                                                        </div>
                                                    </div>
                                                    <h4><strong>You no returns yet</strong></h4>
                                                    <Link to="/products" className="btn btn-info">Start Shopping</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                            {
                                orders.length && orders.length !== totalOrders ? (
                                    <>
                                        <div className="text-center mt-4">
                                            <button className="btn btn-outline-info" onClick={() => handleOrders()}>View More Orders</button>
                                        </div>
                                    </>
                                ) : ''
                            }
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    )
}

export default MyReturns;