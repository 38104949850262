import React, {useContext} from "react";
import {Col, Row} from "react-bootstrap";
import * as moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTruck} from "@fortawesome/free-solid-svg-icons";
import {AppContext} from "../../../context/AppContext";
import {returnOrderRequest} from "../../../requests";
import {toast} from "react-toastify";

const OrderCard = ({order, setShow, setOrderId}) => {
    const {data} = useContext(AppContext);

    // const returnOrder = async (id) => {
    //     const payload = {
    //         'id': id
    //     };
    //     await returnOrderRequest(payload).then((response) => {
    //         if (response.status === 200) {
    //             window.location.reload();
    //         }
    //     }).catch((error) => {
    //         toast.error('Something went wrong', {
    //             theme: "light",
    //             position: "top-right"
    //         })
    //     })
    // };

    return (
        <div className="card my-2">
            <div className="card-body">
                <div className="order-card">
                    <Row>
                        <Col sm={6}>
                            <h6>Order No: <strong>INV_{order.id}</strong></h6>
                        </Col>
                        <Col sm={6}>
                            <h6 className="float-right">Order
                                Date: <strong>{moment(order.created_at).format('dddd, MMM DD, Y')}</strong>
                            </h6>
                        </Col>
                        <Col sm={6}>
                            <h6>Shipping
                                Address: <strong>{JSON.parse(order.shipping_details).address}</strong>
                            </h6>
                        </Col>
                        <Col sm={6}>
                            <h6 className="float-right">Total: <strong>Rs.{order.sub_total + order.tax + order.delivery_charges}</strong>
                            </h6>
                        </Col>
                        <Col sm={12}>
                            <h6 className="text-danger">Discounts:</h6>
                            <p>BilluCLUB
                                Discount: <strong>{order.points_discount}</strong>
                            </p>
                            <p>Promo Code
                                Discount: <strong>{order.promo_code_discount}</strong>
                            </p>
                        </Col>
                    </Row>
                    <div className="card">
                        <div className="card-body">
                            <div className="order-detail">
                                <p><FontAwesomeIcon icon={faTruck}/>&nbsp;
                                <strong className="text-info">
                                    {
                                        order.order_status === 'pending' ? "On Way"
                                            : (order.order_status === 'completed' ? "Delivered"
                                            : (order.order_status === 'cancelled' ? "Cancelled"
                                                : (order.order_status === 'returned' ? "Returned" : "")))
                                    }
                                </strong></p>
                                <div className="order-detail-border"></div>
                                <p className="order-p">Delivered
                                    On: <strong>{moment(order.updated_at).format('dddd, MMM DD, Y')}</strong>
                                </p>
                                <p className="order-p">Sold
                                    By: <strong>{data?.site_data?.site_name}</strong>
                                </p>
                                <strong>{order?.items.length} Items</strong>
                                <div className="checkout-items">
                                    {
                                        order.items.map((item, index) => {
                                            return (
                                                <div className="item" key={index}>
                                                    <img
                                                        src={process.env.REACT_APP_IMAGE_PATH + item.product.default_image}
                                                        alt=""/>
                                                    <div>
                                                        <h5>{item.title}</h5>
                                                        <span>Quantity: {item.qty}</span>
                                                        <p>Rs. {item.price * item.qty}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    order.order_status !== 'returned' ? (
                                        <div className="text-right">
                                            {/*<button className="btn btn-info" onClick={() => returnOrder(order.id)}>Return this order</button>*/}
                                            <button className="btn btn-info" onClick={() => {
                                                setShow(true);
                                                setOrderId(order.id)
                                            }}>Rate this order</button>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OrderCard;