import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {AppContext} from "../context/AppContext";
import parse from "html-react-parser";
import {Container, Row, Col} from "react-bootstrap";

const CMS = () => {
    const {slug} = useParams();
    const {data} = useContext(AppContext);
    const [content, setContent] = useState('');
    useEffect(() => {
        if (typeof data?.site_data != "undefined") {
            Object.entries(data?.site_data).map((name, curr) => {
                if (name[0] === slug) {
                    setContent(name[1]);
                }
            }, []);
        }
    })

    return (
        <>
            <div className="main-content">
                <Container fluid>
                    <Row className="px-xl-5">
                        <Col sm={12}>
                            <div className="card">
                                <div className="card-body text-justify">
                                    <h4 className="underline-heading mb-4">
                                        <span>
                                        {slug.replaceAll('_', ' ').toUpperCase()}
                                        </span>
                                    </h4>
                                    {
                                        data?.site_data ? parse(content) : ''
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default CMS;