import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppContext} from "../../context/AppContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faPhone, faUserAlt} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import {saveOrder} from "../../requests";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {emptyCart} from "../../features/cart/cartSlice";
import {useIsAuthenticated} from "react-auth-kit";
import AuthModal from "../../components/Models/AuthModal";
import Col from "react-bootstrap/Col";

const CartSummary = (props) => {
    const {payment, paymentType, setLoader} = props;
    const cart = useSelector((state) => state.cart);
    const subtotal = useSelector((state) => state.cart.subtotal);
    const {data} = useContext(AppContext);
    const address = useSelector((state) => state.userRedux.active_address);
    const isAuthenticated = useIsAuthenticated();
    const [showLoginModal, setShowLoginModal] = useState(false);

    return (
        cart.items.length ? (
            <>
                <Col lg={4} sm={12}>
                    <div className="cart-summary">
                        <p style={{fontSize: "14px", marginTop: 20, marginBottom: 0, fontWeight: 600}}>Order Summary</p>
                        <div className="card">
                            <div className="card-body">
                                {
                                    isAuthenticated() ? (
                                        <>
                                            <h5>Shipping &amp; Billing</h5>
                                            <div className="mb-3">
                                                <p className="m-0">
                                                    <span className="text-primary">
                                                        <FontAwesomeIcon icon={faUserAlt}/>
                                                    </span>
                                                    &nbsp;{address ? address.receiver_name : ''}{" "}
                                                </p>
                                                <span className="text-primary">
                                                    <FontAwesomeIcon icon={faLocationDot}/>{" "}
                                                </span>
                                                <span>{address ? address.address : ''}</span>
                                            </div>
                                            <p>
                                                 <span className="text-primary">
                                                      <FontAwesomeIcon icon={faPhone}/>
                                                </span>
                                                &nbsp;+{address.contact_number}{" "}
                                            </p>
                                        </>
                                    ) : ''
                                }
                                <h5>Order Summary</h5>
                                <p>
                                    Subtotal ({cart.items.length} items){" "}
                                    <span className="float-right">
                                        <strong>RS. {subtotal.toFixed(2)}</strong>
                                    </span>
                                </p>
                                {
                                    cart.billu_points ? (
                                        <p>
                                            Applied Billu Points{" "}
                                            <span className="float-right">
                                                <strong>RS. {cart.billu_points_amount}</strong>
                                            </span>
                                        </p>
                                    ) : ''
                                }
                                {
                                    data?.site_data?.delivery_charges ? (
                                        <p>
                                            Shipping Fee{" "}
                                            <span className="float-right">
                                                <strong>RS. {data?.site_data?.delivery_charges.toFixed(2)}</strong>
                                            </span>
                                        </p>
                                    ) : ''
                                }
                                <hr className="my-2"/>
                                <h4>
                                    Total{" "}
                                    <span className="float-right text-right">
                                        RS. {(data?.site_data?.delivery_charges + subtotal).toFixed(2)}
                                    </span>
                                </h4>
                            </div>
                        </div>
                        {
                                isAuthenticated() ? (
                                    <Link className="proceed-pay" to="/checkout">
                                        Proceed to Pay
                                    </Link>
                                ) : (
                                    <button className="btn btn-info btn-block proceed-pay"
                                            onClick={() => setShowLoginModal(true)}>
                                        Please Login to checkout
                                    </button>
                                )
                        }

                    </div>
                </Col>
                {
                    showLoginModal && (
                        <AuthModal
                            setShowLoginModal={setShowLoginModal}
                            showLoginModal={showLoginModal}
                            data={data}
                        />
                    )
                }
            </>
        ) : ''
    )
};

export default CartSummary;