import React, {useEffect} from "react";
import CartItems from "./CartSections/CartItems";
import CartSummary from "./CartSections/CartSummary";

const Cart = () => {

    useEffect(() => {
        window.scroll(0,0);
    })

    return (
        <div className="main-content">
            {/* Shop Starts */}
            <div className="container-fluid">
                <div className="cart-section pt-5">
                    <div className="row px-xl-5">
                        <CartItems/>
                        <CartSummary payment={false}/>
                    </div>
                </div>
            </div>
            {/* Shop Ends */}
        </div>

    )
};

export default Cart;