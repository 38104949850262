import React from "react";
import {Rings, Bars} from "react-loader-spinner";

const Loader = () => {
    return (
        <div className="loader">
            <img src="https://billustore.com.pk/backend/site/images/logo.png" alt=""
                 className="logo"/><br/>
            <div>
                <Bars
                    height="35"
                    width="35"
                    color="#36499B"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="rings-loading"
                />
            </div>
        </div>
    )
};

export default Loader;