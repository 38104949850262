import React from "react";
import {Link} from "react-router-dom";

const CategoryCard = (props) => {
    const {category} = props;

    return (
        <Link to={`/categories/${category.slug}`}>
            <div className="category-section">
                <img src={process.env.REACT_APP_IMAGE_PATH + category?.image} alt={category?.category}/>
                <h4>{category?.category}</h4>
            </div>
        </Link>
    )
};

export default CategoryCard;