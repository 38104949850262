import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faLocationDot, faLock, faTimes, faUser} from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Controller, useForm} from "react-hook-form";
import Select from "react-select";
import {getCities, userLogin, UserRegister} from "../../requests";
import ClipLoader from "react-spinners/ClipLoader";
import {toast} from "react-toastify";
import {useSignIn} from 'react-auth-kit'
import {useDispatch} from "react-redux";
import {getAddresses, getUserDetail, getWishList} from "../../features/user/userSlice";

const AuthModal = (props) => {
    const {showLoginModal, setShowLoginModal, data} = props;
    const [defaultTab, setDefaultTab] = useState('login');
    const {register, handleSubmit, formState: {errors}, control} = useForm();
    const handleSelect = (eventKey) => setDefaultTab(eventKey);
    const [options, setOptions] = useState(null);
    const [loader, setLoader] = useState(false);
    const signIn = useSignIn();
    const dispatch = useDispatch();

    const login = (data) => {
        setLoader(true);
        userLogin(data).then((response) => {
            if (signIn(
                {
                    token: response.data.data.token,
                    expiresIn: 1440,
                    tokenType: "Bearer",
                    authState: response.data.data.email
                }
            )) {
                localStorage.setItem('__site_pass', JSON.stringify(response.data.data));
                const payload = {
                    token: response.data.data.token,
                    secure_pass: response.data.data.secure_pass
                };

                const queryParams = new URLSearchParams(window.location.search)
                const term = queryParams.get("login")

                dispatch(getUserDetail(payload));
                dispatch(getAddresses(payload));
                dispatch(getWishList(payload));
                setShowLoginModal(false);
                setLoader(false);
            } else {
                toast.error("Something went wrong during login", {
                    theme: "light",
                    position: "top-right"
                })
            }
        }).catch((e) => {
            setLoader(false);
            toast.error(e.response.data.message, {
                theme: "light",
                position: "top-right"
            })
        })
    };

    const registerUser = (data) => {
        setLoader(true);
        let payload = {
            email: data.email,
            password: data.password,
            first_name: data.first_name,
            last_name: data.last_name,
            city_id: data.city.value
        };

        UserRegister(payload).then((response) => {
            if (response.data.status_code === 500) {
                toast.error(response.data.message, {
                    theme: "light",
                    position: "top-right"
                })
            }

            if (response.data.status_code === 200) {
                toast.success(response.data.message, {
                    theme: "light",
                    position: "top-right"
                });
                setLoader(false);
                setShowLoginModal(false);
            }
        });
    };

    const getCitiesForSignup = async () => {
        await getCities().then((res) => {
            setOptions(res?.data?.data?.cities)
        })
    };

    useEffect(() => {
        if (!options) {
            getCitiesForSignup()
        }
    });

    return (
        <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}
               className="login-modal">
            <Modal.Header className="login-modal-header">
                <div className="login-logo">
                    <img src={process.env.REACT_APP_IMAGE_PATH + data?.site_data?.logo} alt=""
                         className="logo"/>
                </div>
                <span onClick={() => setShowLoginModal(false)}>
                                <FontAwesomeIcon icon={faTimes}/>
                            </span>
            </Modal.Header>
            <Modal.Body className="login-modal-body">
                <div className="login-modal-tabs">
                    <Nav justify variant="tabs" activeKey={defaultTab} onSelect={handleSelect}>
                        <Nav.Item>
                            <Nav.Link eventKey="login">Login</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="register">Sign up</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className="login-modal-form">
                    {
                        defaultTab === 'login' ? (
                            <form onSubmit={handleSubmit(login)}>
                                <div className="login-form"
                                     style={{minHeight: '300px'}}>
                                    <InputGroup size="lg">
                                        <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                        <Form.Control
                                            placeholder="Email"
                                            aria-label="Email"
                                            className={errors?.email ? 'is-invalid' : ''}
                                            aria-describedby="basic-addon1"
                                            {
                                                ...register('email',
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Email is required'
                                                        },
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: 'Email is invalid'
                                                        }
                                                    })
                                            }
                                        />
                                    </InputGroup>
                                    {
                                        errors?.email && (
                                            <span className="text-danger">{errors?.email?.message}</span>)
                                    }
                                    <InputGroup className="mt-3" size="lg">
                                        <InputGroup.Text id="basic-addon1">
                                            <FontAwesomeIcon icon={faLock}/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Password"
                                            aria-label="Username"
                                            type="password"
                                            className={errors?.password ? 'is-invalid' : ''}
                                            aria-describedby="basic-addon1"
                                            {
                                                ...register('password',
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Password is required'
                                                        }
                                                    })
                                            }
                                        />
                                    </InputGroup>
                                    {
                                        errors?.password && (<span
                                            className="text-danger">{errors?.password?.message}</span>)
                                    }
                                    <Form.Text id="passwordHelpBlock" className="my-3">
                                        <a className="text-dark" href="">Don't remember your password?</a>
                                    </Form.Text>
                                    <Button type="submit" disabled={loader}>
                                        {
                                            loader ? (
                                                <ClipLoader
                                                    color="#fff"
                                                    loading={true}
                                                    size={20}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            ) : (
                                                <>
                                                    Login <FontAwesomeIcon icon={faChevronRight}/>
                                                </>
                                            )
                                        }
                                    </Button>
                                </div>
                            </form>
                        ) : (
                            <form onSubmit={handleSubmit(registerUser)}>
                                <div className="login-form"
                                     style={{minHeight: '530px'}}>
                                    <InputGroup className="mt-3" size="lg">
                                        <InputGroup.Text id="basic-addon1">
                                            <FontAwesomeIcon icon={faUser}/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="First Name"
                                            aria-label="First Name"
                                            className={errors?.first_name ? 'is-invalid' : ''}
                                            aria-describedby="basic-addon1"
                                            {
                                                ...register('first_name',
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'First name is required'
                                                        }
                                                    })
                                            }
                                        />
                                    </InputGroup>
                                    {
                                        errors?.first_name && (<span
                                            className="text-danger">{errors?.first_name?.message}</span>)
                                    }
                                    <InputGroup className="mt-3" size="lg">
                                        <InputGroup.Text id="basic-addon1">
                                            <FontAwesomeIcon icon={faUser}/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Last Name"
                                            aria-label="Last Name"
                                            className={errors?.last_name ? 'is-invalid' : ''}
                                            aria-describedby="basic-addon1"
                                            {
                                                ...register('last_name',
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Last name is required'
                                                        }
                                                    })
                                            }
                                        />
                                    </InputGroup>
                                    {
                                        errors?.last_name && (<span
                                            className="text-danger">{errors?.last_name?.message}</span>)
                                    }
                                    <InputGroup className="mt-3" size="lg">
                                        <InputGroup.Text id="basic-addon1">
                                            @
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Email Address"
                                            aria-label="Email Address"
                                            className={errors?.email ? 'is-invalid' : ''}
                                            aria-describedby="basic-addon1"
                                            {
                                                ...register('email',
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Email address is required'
                                                        }
                                                    })
                                            }
                                        />
                                    </InputGroup>
                                    {
                                        errors?.email && (
                                            <span className="text-danger">{errors?.email?.message}</span>)
                                    }
                                    <InputGroup className="mt-3" size="lg">
                                        <InputGroup.Text id="basic-addon1">
                                            <FontAwesomeIcon icon={faLock}/>
                                        </InputGroup.Text>
                                        <Form.Control
                                            placeholder="Password"
                                            aria-label="Password"
                                            type="password"
                                            className={errors?.password ? 'is-invalid' : ''}
                                            aria-describedby="basic-addon1"
                                            {
                                                ...register('password',
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Password is required'
                                                        }
                                                    })
                                            }
                                        />
                                    </InputGroup>
                                    {
                                        errors?.password && (<span
                                            className="text-danger">{errors?.password?.message}</span>)
                                    }
                                    <InputGroup className="mt-3" size="lg">
                                        <InputGroup.Text id="basic-addon1">
                                            <FontAwesomeIcon icon={faLocationDot}/>
                                        </InputGroup.Text>
                                        <Controller
                                            render={({field: {onChange, value}}) => (
                                                <Select
                                                    options={options}
                                                    className="w-91 text-left"
                                                    placeholder="Select City"
                                                    defaultValue={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                            name="city_id"
                                            control={control}
                                            {...register('city', {
                                                required: {
                                                    value: true,
                                                    message: 'City is required'
                                                }
                                            })}
                                        />

                                    </InputGroup>
                                    {
                                        errors?.city && (
                                            <span className="text-danger">{errors?.city?.message}</span>)
                                    }
                                    <Button type="submit" disabled={loader}>
                                        {
                                            loader ? (
                                                <ClipLoader
                                                    color="#fff"
                                                    loading={true}
                                                    size={20}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            ) : (
                                                <>
                                                    Sign up <FontAwesomeIcon icon={faChevronRight}/>
                                                </>
                                            )
                                        }
                                    </Button>
                                </div>
                            </form>
                        )
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default AuthModal;