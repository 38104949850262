import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMotorcycle, faCheckCircle, faSearch} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import {getCategoryProducts} from "../../requests";

const CategoriesFilter = (props) => {
    const {
        categories,
        brands,
        minPrice,
        maxPrice,
        setProducts,
        setTotalProducts,
        setTotalPages,
        setCategories,
        setBrands,
        setMinPrice,
        setMaxPrice,
        setLoader,
        currentPage,
        slug
    } = props;
    const [brandsArr, setBrandsArr] = useState(brands);
    const [selectedCategory, setSelectedCategory] = useState(categories?.id);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [price, setPrice] = useState([minPrice,maxPrice]);

    const filterBrand = (e) => {
        let filtered_brands;
        if (e) {

             filtered_brands = brands.filter((brand) => {
                if (brand.title.includes(e)) {
                    return brand;
                }
            });
        } else {
            filtered_brands = brands;
        }

        setBrandsArr(filtered_brands);
    };

    const filterProducts = () => {
        let limit = 12;
        setLoader(true);
        getCategoryProducts(currentPage, slug, selectedBrand, price[0], price[1]).then((res) => {
            setProducts(res.data.data.products);
            setTotalProducts(res.data.data.total_products);
            setTotalPages(Math.ceil(res.data.data.total_products / limit).toFixed(0));
            setCategories(res.data.data.category);
            setBrands(res.data.data.brands);
            setMinPrice(res.data.data.minPrice);
            setMaxPrice(res.data.data.maxPrice);
            setLoader(false);
        });
    }

    const resetFilters = () => {
        setLoader(true);
        let limit = 12;
        getCategoryProducts(currentPage, slug).then((res) => {
            setProducts(res.data.data.products);
            setTotalProducts(res.data.data.total_products);
            setTotalPages(Math.ceil(res.data.data.total_products / limit).toFixed(0));
            setCategories(res.data.data.category);
            setBrands(res.data.data.brands);
            setMinPrice(res.data.data.minPrice);
            setMaxPrice(res.data.data.maxPrice);
            setSelectedBrand('');
            setLoader(false);
        });
    }

    return (
        <>
            <div className="filters shadow">
                {/*<div className="filter-section">*/}
                {/*    <h5>Delivery Type</h5>*/}
                {/*    <ul className="delivery-filters">*/}
                {/*        <li>*/}
                {/*            <a href="" className="active">*/}
                {/*                <FontAwesomeIcon icon={faMotorcycle}/> Now <span>(36)</span>*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
                {/*<hr/>*/}
                <div className="filter-section">
                    <button className="btn btn-info btn-block" onClick={() => resetFilters()}>Reset Filters</button>
                    {/*<ul c lassName="delivery-filters">*/}
                    {/*    <li>*/}
                    {/*        <a href="" className="active">*/}
                    {/*            <FontAwesomeIcon icon={faMotorcycle}/> Now <span>(36)</span>*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                </div>
                <hr/>
                <div className="filter-section">
                    <h5>Categories</h5>
                    <ul className="common-filters">
                        {
                            categories && typeof categories !== 'undefined' ?
                                <>
                                    {
                                        categories.parent_category ? (
                                            <h5 className="text-info">{categories.parent_category.category}</h5>
                                        ) : ''
                                    }
                                    {
                                        categories.parent_category.parent_category ? (
                                            <h5 className="ml-3 text-info">{categories.parent_category.parent_category.category}</h5>
                                        ) : ''
                                    }
                                    <Link className="ml-4" href="javascript:void(0);" onClick={() => setSelectedCategory(categories.id)}>
                                        <FontAwesomeIcon icon={faCheckCircle} className="text-info" />{" "}
                                        {categories.category} <span>({categories.products.length})</span>
                                    </Link>
                                </>
                                : ''
                        }
                    </ul>
                </div>
                <hr/>
                <div className="filter-section">
                    <h5>Brand</h5>
                    <div className="filter_input">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <FontAwesomeIcon icon={faSearch}/>
                              </span>
                            </div>
                            <input
                                type="text"
                                className="form-control bg-transparent"
                                placeholder="Filter Brands"
                                onChange={(e) => filterBrand(e.target.value)}
                            />
                        </div>
                    </div>
                    <ul className="common-filters">
                        {
                            brandsArr.map((brand, index) => {
                                return (
                                    <li onClick={() => setSelectedBrand(brand.id)} key={index}>
                                        {
                                            selectedBrand === brand.id ? (
                                                <>
                                                    <FontAwesomeIcon icon={faCheckCircle} className="text-info" />
                                                    {" "}
                                                </>
                                            ) : ''
                                        }
                                        <a href="javascript:void(0);">
                                            {brand?.title} <span>({brand?.products.length})</span>
                                        </a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <hr/>
                <div className="filter-section">
                    <h5>Shop by Price</h5>
                    <div className="my-4">
                        <RangeSlider
                            min={minPrice}
                            max={maxPrice}
                            defaultValue={[minPrice,maxPrice]}
                            onInput={(e) => {
                                setPrice(e)
                            }}
                        />
                    </div>
                    <div className="price-blocks">
                        <div className="price-input">
                            <strong>PKR</strong>
                            <input type="text" value={price[0]}/>
                        </div>
                        <span>to</span>
                        <div className="price-input">
                            <strong>PKR</strong>
                            <input type="text" value={price[1]}/>
                        </div>
                        <a href="javascript:void(0);" onClick={() => filterProducts()} className="go-btn text-info">GO</a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CategoriesFilter;