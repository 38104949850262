import {createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        subtotal: 0,
        delivery_charges: 0,
        points: 0,
        points_amount: 0,
        points_applied: false,
        promo_code_applied: false,
        promo_code_discount: 0
    },
    reducers: {
        setCart: (state) => {
            let items = localStorage.getItem('cart');
            items = items ? JSON.parse(items) : [];
            state.items = items;
            let items_total = 0;
            if (items.length) {
                items.map((item, index) => {
                    items_total = items_total + (item.price * item.qty);
                });
            }
            state.subtotal = items_total;
        },
        addItem: (state, action) => {
            const itemInCart = state.items.find((item) => item.id === action.payload.id);
            let items_total = 0;
            const items = state.items;

            if (itemInCart) {
                itemInCart.qty++;
            } else {
                state.items.push({ ...action.payload, qty: 1 });
            }

            items.map((item, index) => {
                items_total = items_total + (item.price * item.qty);
            });

            state.subtotal = items_total;
            toast.success("Item added to cart", {
                position: "top-right",
                theme: "light",
                hideProgressBar: true
            });
            localStorage.setItem('cart', JSON.stringify(state.items));
        },
        incrementQuantity: (state, action) => {
            const item = state.items.find((item) => item.id === action.payload);
            item.qty++;
            const items = state.items;
            let items_total = 0;
            items.map((item, index) => {
                items_total = items_total + (item.price * item.qty);
            });

            state.subtotal = items_total;
            toast.success("Item added to cart", {
                position: "top-right",
                theme: "light",
                hideProgressBar: true
            });
            localStorage.setItem('cart', JSON.stringify(state.items));
        },
        decrementQuantity: (state, action) => {
            const item = state.items.find((item) => item.id === action.payload);
            if (item.qty === 1) {
                item.qty = 1
            } else {
                item.qty--;
            }

            const items = state.items;
            let items_total = 0;
            items.map((item, index) => {
                items_total = items_total + (item.price * item.qty);
            });
            toast.error("Item removed from cart", {
                position: "top-right",
                theme: "light",
                hideProgressBar: true
            });
            state.subtotal = items_total;
            localStorage.setItem('cart', JSON.stringify(state.items));
        },
        removeItem: (state, action) => {
            state.items = state.items.filter((item) => item.id !== action.payload);
            const items = state.items;
            let items_total = 0;
            items.map((item, index) => {
                items_total = items_total + (item.price * item.qty);
            });
            toast.error("Item removed from cart", {
                position: "top-right",
                theme: "light",
                hideProgressBar: true
            });
            state.subtotal = items_total;
            localStorage.setItem('cart', JSON.stringify(state.items));
        },
        emptyCart: (state,action) => {
            state.items = [];
        },
        setPoints: (state, action) => {
            state.points = action.payload.point;
            state.points_amount = parseFloat(action.payload.point) * parseFloat(action.payload.point_amount);
            state.points_amount = state.points_amount.toFixed(2);
            const items = state.items;
            let items_total = 0;
            items.map((item, index) => {
                items_total = items_total + (item.price * item.qty);
            });

            items_total = items_total - state.points_amount;
            state.subtotal = items_total;
            state.points_applied = true;
        },
        setPromoCodeInRedux: (state, action) => {
            state.promo_code_discount = action.payload;
            const items = state.items;
            let items_total = 0;
            items.map((item, index) => {
                items_total = items_total + (item.price * item.qty);
            });

            items_total = items_total - state.promo_code_discount;
            state.subtotal = items_total;
            state.promo_code_applied = true;
        }
    }
});

export const {setCart, addItem, decrementQuantity, incrementQuantity, removeItem, emptyCart, setPoints, setPromoCodeInRedux} = cartSlice.actions;
export default cartSlice.reducer;