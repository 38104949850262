import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoxes,
    faHeart,
    faMap,
    faRightFromBracket,
    faUser,
    faWallet
} from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../../requests";
import {toast} from "react-toastify";
import {useSignOut} from "react-auth-kit";

const MobileNavbar = () => {
    const signOut = useSignOut();

    const userLogout = async () => {
        await logout().then((response) => {
            localStorage.removeItem('__site_pass');
            signOut();
        }).catch((error) => {
            console.log(error)
            toast.error("Something went wrong", {
                theme: "light",
                position: "top-right"
            })
        })
    };

    return (
        <Navbar variant="light" className="shadow" bg="light" expand="lg">
            <Container fluid>
                <Navbar.Toggle aria-controls="navbar-light-example"/>
                <Navbar.Collapse id="navbar-light-example">
                    <Row className="w-100 px-xl-5">
                        <Col sm={12}>
                            <Nav>
                                <Nav.Link href="/profile">
                                    <FontAwesomeIcon className="mr-3"
                                                     icon={faUser}/> Profile
                                </Nav.Link>
                                <Nav.Link href="/profile">
                                    <FontAwesomeIcon className="mr-3"
                                                     icon={faWallet}/> BilluClub
                                </Nav.Link>
                                <Nav.Link href="/profile">
                                    <FontAwesomeIcon className="mr-3"
                                                     icon={faBoxes}/> My Orders
                                </Nav.Link>
                                {/*<Nav.Link href="/profile">*/}
                                {/*    <FontAwesomeIcon className="mr-3"*/}
                                {/*                     icon={faBoxes}/> My Returns*/}
                                {/*</Nav.Link>*/}
                                <Nav.Link href="/profile">
                                    <FontAwesomeIcon className="mr-3"
                                                     icon={faHeart}/> My Wishlist
                                </Nav.Link>
                                <Nav.Link href="/profile">
                                    <FontAwesomeIcon className="mr-3"
                                                     icon={faMap}/> My Addresses
                                </Nav.Link>
                                <Nav.Link href="javascript:void(0);" onClick={() => userLogout()}>
                                    <FontAwesomeIcon className="mr-3"
                                                     icon={faRightFromBracket}/> Logout
                                </Nav.Link>
                            </Nav>
                        </Col>
                    </Row>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};

export default MobileNavbar;
