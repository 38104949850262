import React from "react";
import {Route, Routes} from "react-router-dom";
import {RequireAuth} from "react-auth-kit";
import Home from "../pages/Home";
import Products from "../pages/Products";
import Product from "../pages/Product";
import Cart from "../pages/Cart";
import Verification from "../pages/Verification";
import Checkout from "../pages/Checkout";
import Profile from "../pages/Dashboard/Profile";
import PointsHistory from "../pages/Dashboard/PointsHistory";
import BilluClub from "../pages/Dashboard/BilluClub";
import MyOrders from "../pages/Dashboard/MyOrders";
import MyReturns from "../pages/Dashboard/MyReturns";
import WishList from "../pages/Dashboard/MyWishList";
import MyAddresses from "../pages/Dashboard/MyAddresses";
import CMS from "../pages/CMS";
import CategoryProducts from "../pages/CategoryProducts";

const APPRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/pages/:slug" element={<CMS/>}/>
            <Route path="/products" element={<Products/>}/>
            <Route path="/categories/:slug" element={<CategoryProducts/>}/>
            <Route path="/product/:slug" element={<Product/>}/>
            <Route path="/cart" element={<Cart/>}/>
            <Route path="/checkout" element={<Checkout/>}/>
            <Route path="/verify_token" element={<Verification/>} />
            <Route path="/billu-club" element={
                <RequireAuth loginPath="/">
                    <BilluClub/>
                </RequireAuth>
            } />
            <Route path="/billu-points" element={
                <RequireAuth loginPath="/">
                    <PointsHistory/>
                </RequireAuth>
            } />
            <Route path="/profile" element={
                <RequireAuth loginPath="/">
                    <Profile/>
                </RequireAuth>
            } />
            <Route path="/my-orders" element={
                <RequireAuth loginPath="/">
                    <MyOrders/>
                </RequireAuth>
            } />
            <Route path="/my-returns" element={
                <RequireAuth loginPath="/">
                    <MyReturns/>
                </RequireAuth>
            } />
            <Route path="/my-wishlist" element={
                <RequireAuth loginPath="/">
                    <WishList/>
                </RequireAuth>
            } />
            <Route path="/my-addresses" element={
                <RequireAuth loginPath="/">
                    <MyAddresses/>
                </RequireAuth>
            } />
        </Routes>
    )
};

export default APPRoutes;