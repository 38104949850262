import React from "react";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import {logout} from "../../../requests";
import {toast} from "react-toastify";
import {useSignOut} from "react-auth-kit";

const Sidebar = (props) => {
    const {activeTab} = props;
    const signOut = useSignOut();

    const userLogout = async () => {
        await logout().then((response) => {
            localStorage.removeItem('__site_pass');
            signOut();
        }).catch((error) => {
            console.log(error)
            toast.error("Something went wrong", {
                theme: "light",
                position: "top-right"
            })
        })
    }

    return (
        <Col lg={3} className="d-none d-md-none d-lg-block">
            <div className="dashboard-sidebar">
                <ul>
                    <li>
                        <Link to="/profile"
                              className={activeTab === 'profile' ? 'active' : ''}>My Profile</Link>
                    </li>
                    <li>
                        <Link to="/billu-club"
                              className={activeTab === 'billu-points' || activeTab === 'billu-club' ? 'active' : ''}>BilluClub</Link>
                    </li>
                    <li>
                        <Link to="/my-orders" className={activeTab === 'my-orders' ? 'active' : ''}>My Orders</Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to="/my-returns" className={activeTab === 'my-returns' ? 'active' : ''}>My Returns</Link>*/}
                    {/*</li>*/}
                    <li>
                        <Link to="/my-wishlist" className={activeTab === 'my-wishlist' ? 'active' : ''}>My Wishlist</Link>
                    </li>
                    <li>
                        <Link to="/my-addresses" className={activeTab === 'my-addresses' ? 'active' : ''}>My Addresses</Link>
                    </li>
                    <li><Link onClick={() => userLogout()}  to="/">Logout</Link></li>
                </ul>
            </div>
        </Col>
    )
}

export default Sidebar;