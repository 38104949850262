import React, {useContext, useState} from "react";
// import CartSummary from "./CartSections/CartSummary";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AppContext} from "../context/AppContext";
import {useSelector} from "react-redux";
import {
    faMapMarkerAlt,
    faMoneyBillAlt,
    faPhone,
    faTimes, faUser,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loader from "../components/Loader";
import {useDispatch} from "react-redux";
import {emptyCart, setPoints, setPromoCodeInRedux} from "../features/cart/cartSlice";
import {useNavigate} from "react-router-dom";
import AuthModal from "../components/Models/AuthModal";
import {useIsAuthenticated} from "react-auth-kit";
import {applyPromoCode, saveOrder} from "../requests";
import {toast} from "react-toastify";
// import {useForm} from "react-hook-form";

const Checkout = () => {
    const {data} = useContext(AppContext);
    const cart = useSelector((state) => state.cart);
    const billu_point = useSelector((state) => state.cart.points);
    const user = useSelector((state) => state.userRedux.user);
    const paymentType = 'cod';
    const address = useSelector((state) => state.userRedux.active_address);
    const [loader, setLoader] = useState(false);
    const [applyPoints, setApplyPoints] = useState(false);
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoCodeError, setPromoCodeError] = useState(null);
    const [promoCodeId, setPromoCodeId] = useState(null);
    const history = useNavigate();

    const applyPointsOnCheckout = (e) => {
        if (user.billu_points <= e.target.value) {
            document.getElementById('points_input').disabled = true;
        }

        dispatch(setPoints({point: e.target.value, point_amount: data?.site_data?.amount_to_be_used_on_points}));
    };

    const applyCode = async () => {
        if (!promoCode) {
            setPromoCodeError('Please enter the code');
            return;
        }

        let payload = {
            'code': promoCode,
            'amount': cart.subtotal
        };

        await applyPromoCode(payload).then((response) => {
            setPromoCodeId(response.data.result.history_id);
            dispatch(setPromoCodeInRedux(response.data.result.amount));

            toast.success(response.data.message, {
                theme: "light",
                position: "top-right"
            })
        }).catch((error) => {
            toast.error(error.response.data.message, {
                theme: "light",
                position: "top-right"
            })
        })
    };

    const placeOrder = async () => {
        setLoader(true);
        let items = [];
        cart.items.map((item, index) => {
            let object = {
                id: item.id,
                qty: item.qty,
                price: item.price
            };

            items.push(object);
        });

        const payload = {
            cart_items: JSON.stringify(items),
            shipping_details: JSON.stringify(address),
            payment_method: paymentType,
            sub_total: cart.subtotal,
            tax: 0,
            delivery_charges: data?.site_data?.delivery_charges,
            points_discount: cart.points_amount,
            points_applied: cart.points_applied,
            points: cart.points,
            promoCode: cart.promo_code_applied,
            promo_code_discount: cart.promo_code_discount,
            promoCodeId: promoCodeId
        };

        await saveOrder(payload).then((response) => {
            setLoader(false);
            history('/');
            dispatch(emptyCart());
            localStorage.removeItem('cart');
            toast.success(response.data.message, {
                theme: "light",
                position: "top-right"
            })
        }).catch((error) => {
            toast.error("Something went wrong", {
                theme: "light",
                position: "top-right"
            })
        })

    };

    return (
        loader ? (
            <Loader/>
        ) : (
            <div className="main-content">
                {/* Shop Starts */}
                <div className="container-fluid">
                    <div className="cart-section pt-lg-5">
                        <Row className="px-xl-5">
                            <Col sm={12} lg={8}>
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <Row>
                                            <Col sm={12}>
                                                <div className="delivery pl-5">
                                                    <h4>Delivery Address</h4>
                                                    <p className="map-heading">
                                                        <FontAwesomeIcon icon={faMapMarkerAlt}
                                                                         className="text-primary"/> {address ? address?.labels.toUpperCase() : ''}
                                                    </p>
                                                    <p>
                                                        {address?.address}
                                                    </p>
                                                    <p className="map-heading">
                                                        <FontAwesomeIcon icon={faUser}
                                                                         className="text-primary"/> {address ? address?.receiver_name : ''}
                                                    </p>
                                                    <p className="map-heading">
                                                        <FontAwesomeIcon icon={faPhone}
                                                                         className="text-primary"/> {address ? address?.contact_number : ''}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <Row>
                                            <Col sm={12}>
                                                <div className="shipment">
                                                    <h4>Shipment form</h4>
                                                    <div className="shipment-detail">
                                                        <Row className="pl-5">
                                                            <Col sm={6}>
                                                                <p>Standard Delivery</p>
                                                            </Col>
                                                            {/*<div className="col-6 text-right">*/}
                                                            {/*    <a href>Change</a>*/}
                                                            {/*</div>*/}
                                                        </Row>
                                                        <div className="row pl-5">
                                                            <div className="col-lg-8">
                                                                {/*<div className="d-flex align-items-center">*/}
                                                                {/*    <p>Delivery Slot</p>*/}
                                                                {/*    <select name id className="delivery-dropdown">*/}
                                                                {/*        <option value>Today 4 Oct, 4pm - 7pm</option>*/}
                                                                {/*        <option value>Today 4 Oct, 4pm - 7pm</option>*/}
                                                                {/*    </select>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                            <div className="col-lg-4 delivery-charges">
                                                                <p>Delivery
                                                                    Fee: <strong>Rs. {data?.site_data?.delivery_charges}</strong>
                                                                </p>
                                                            </div>
                                                            <Col sm={12}>
                                                                <div className="checkout-items">
                                                                    {
                                                                        cart.items.length ?
                                                                            cart.items.map((item, index) => {
                                                                                return (
                                                                                    <div className="item">
                                                                                        <img
                                                                                            src={process.env.REACT_APP_IMAGE_PATH + item.image}
                                                                                            alt=""/>
                                                                                        <div>
                                                                                            <h5>{item.title}</h5>
                                                                                            <span>Quantity: {item.qty}</span>
                                                                                            <p>Rs. {item.price * item.qty}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }) : (
                                                                                <>
                                                                                    <div className="text-center">
                                                                                        No Items
                                                                                    </div>
                                                                                </>
                                                                            )

                                                                    }

                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <p style={{fontSize: "14px", margin: "10px 0", fontWeight: 600}}>Payment Method</p>
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <Row>
                                            <Col sm={12}>
                                                <div className="payment-methods">
                                                    <div className="row pl-5">
                                                        <div className="col-8">
                                                            <div className="d-flex align-items-center payment-method">
                                                                <p className="mr-2">
                                                                    <FontAwesomeIcon icon={faMoneyBillAlt}
                                                                                     style={{fontSize: 18}}/>
                                                                </p>
                                                                <div>
                                                                    <h5 className="m-0">Cash on delivery</h5>
                                                                    <p>No additional fee</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className="col-4 text-right">*/}
                                                        {/*    <button>Change</button>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <p style={{fontSize: "14px", margin: "10px 0", fontWeight: 600}}>Loyality and
                                    Discounts</p>
                                <div className="card mt-4">
                                    <div className="card-body">
                                        <Row>
                                            {
                                                data?.site_data?.allow_billu_points ? (
                                                    <Col sm={12}>
                                                        <div className="loyality-section">
                                                            <div className="row pl-5">
                                                                <div className="col-8">
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={window.location.origin + "/img/billuCLUB.png"} alt=""/>
                                                                        <div className="mt-4 ml-3">
                                                                            <h5>BilluCLUB Balance</h5>
                                                                            <p>{user.billu_points} available</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4 text-right">
                                                                    {
                                                                        !applyPoints ? (
                                                                            <a href className="loyality-anch"
                                                                               onClick={() => {
                                                                                   setApplyPoints(true)
                                                                               }}>Partial Redeem</a>
                                                                        ) : (
                                                                            <>
                                                                                <div className="form-group">
                                                                                    <Row>
                                                                                        <Col sm={6}></Col>
                                                                                        <Col sm={4}>
                                                                                            <input id="points_input"
                                                                                                   type="number"
                                                                                                   className="form-control"
                                                                                                   defaultValue={billu_point}
                                                                                                   onChange={(e) => {
                                                                                                       applyPointsOnCheckout(e)
                                                                                                   }}/>
                                                                                        </Col>
                                                                                        <Col sm={1}>
                                                                                            <a href
                                                                                               className="text-danger"
                                                                                               onClick={() => {
                                                                                                   setApplyPoints(false)
                                                                                               }}><FontAwesomeIcon
                                                                                                icon={faTimes}/></a>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ) : ''
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} sm={12} className="mt-4">
                                <div className="cart-summary">
                                    <p style={{fontSize: "14px", margin: 0, fontWeight: 600}}>Promo Code</p>
                                    <div className="card card-body">
                                        <div className="promo-code-wrap">
                                            <input type="text" placeholder="Promo Code" defaultValue={promoCode}
                                                   onChange={(e) => {
                                                       setPromoCodeError(null);
                                                       setPromoCode(e.target.value);
                                                   }}/> <span onClick={() => {
                                            applyCode()
                                        }}>Apply</span>
                                            {
                                                promoCodeError ? (
                                                    <p className="m-0 text-danger">{promoCodeError}</p>
                                                ) : ''
                                            }
                                        </div>
                                    </div>
                                    <p style={{fontSize: "14px", marginTop: 20, marginBottom: 0, fontWeight: 600}}>Order
                                        Summary</p>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5>Shipping &amp; Billing</h5>
                                            <div className="mb-3">
                                                <p className="m-0">
                                                    Subtotal <strong
                                                    className="float-right">Rs. {cart.subtotal}</strong>
                                                </p>
                                                {
                                                    cart.billu_points ? (
                                                        <p>
                                                            Applied Billu Points{" "}
                                                            <strong
                                                                className="float-right">Rs. {cart.points_amount}</strong>
                                                        </p>
                                                    ) : ''
                                                }
                                                {
                                                    data?.site_data?.delivery_charges ? (
                                                        <p>
                                                            Shipping Fee{" "}
                                                            <strong
                                                                className="float-right">Rs. {data?.site_data?.delivery_charges.toFixed(2)}</strong>
                                                        </p>
                                                    ) : ''
                                                }
                                            </div>
                                            <hr/>
                                            <div className="mb-3">
                                                <p className="m-0">
                                                    Total <strong
                                                    className="float-right">Rs. {(cart.subtotal + cart.points_amount + data?.site_data?.delivery_charges).toFixed(2)}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        isAuthenticated() ? (
                                            <button className="proceed-pay border-0 text-left px-4"
                                                    onClick={() => placeOrder()}>
                                                Proceed to Pay <strong
                                                className="float-right">Rs. {(cart.subtotal + cart.points_amount + data?.site_data?.delivery_charges).toFixed(2)}</strong>
                                            </button>
                                        ) : (
                                            <button className="btn btn-info btn-block proceed-pay"
                                                    onClick={() => setShowLoginModal(true)}>
                                                Please Login to checkout
                                            </button>
                                        )
                                    }

                                </div>
                            </Col>
                            {
                                showLoginModal && (
                                    <AuthModal
                                        setShowLoginModal={setShowLoginModal}
                                        showLoginModal={showLoginModal}
                                        data={data}
                                    />
                                )
                            }
                        </Row>
                    </div>
                </div>
                {/* Shop Ends */}
            </div>
        )
    )
}

export default Checkout;