import React from "react";
import {addToCart, calculatePrice} from "../../helpers/productsHelper";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {addItem} from "../../features/cart/cartSlice";
import {useIsAuthenticated} from "react-auth-kit";
import {addToWishlist} from "../../requests";
import {toast} from "react-toastify";
import {addItemToWishList} from "../../features/user/userSlice";

const RegularProduct = (props) => {
    const {product} = props;
    const isAuthenticated = useIsAuthenticated();
    const dispatch = useDispatch();

    const handleWishList = async (id) => {
        const payload = {
            'product_id': id
        };

        await addToWishlist(payload).then((response) => {
            dispatch(addItemToWishList(response.data.data));
            toast.success(response.data.message, {
                theme: "light",
                position: "top-right"
            })
        }).catch((error) => {
            toast.error(error.response.data.message, {
                theme: "light",
                position: "top-right"
            })
        })
    }

    return (
        <div className="product-section-1 mb-5 shadow">
            {
                product?.apply_discount ? (
                    <span
                        className="discount-badge">{product?.discount_value}{product?.discount_type === 'value' ? ' Rs' : '%'} off</span>
                ) : ''
            }
            <a href={`/product/` + product?.slug}>
                <h4>{product?.product_title}</h4>
            </a>
            <p className="unit">{product?.unit_value} {product?.unit?.prefix}</p>
            <p className="price">Rs.{calculatePrice(product)}</p>
            <div className="img">
                <img src={process.env.REACT_APP_IMAGE_PATH + product?.default_image} width={150} alt=""/>
            </div>
            <div className="product-button">
                <a href="javascript:void(0);" onClick={() => dispatch(addItem({
                    title: product?.product_title,
                    image: product?.default_image,
                    price: calculatePrice(product),
                    id: product?.id
                }))} className="btn">
                    <img src={window.location.origin + "/img/cart.svg"} className="cart" alt=""/>
                </a>
                {
                    isAuthenticated() ? (
                        <a href="javascript:void(0);" className="btn" onClick={() => handleWishList(product?.id)}>
                            <img src={window.location.origin + "/img/heart.svg"} alt=""/>
                        </a>
                    ) : ''
                }
            </div>
        </div>
    )
};

export default RegularProduct;