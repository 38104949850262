import React, {useEffect, useState} from "react";
import {Container, Row, Col, Modal} from "react-bootstrap";
import Sidebar from "./common/Sidebar";
import {Link} from "react-router-dom";
import {getMyOrders, saveReview} from "../../requests";
import Loader from "../../components/Loader";
import {toast} from "react-toastify";
import OrderCard from "./common/OrderCard";
import MobileNavbar from "./common/MobileNavbar";
import StarRatings from 'react-star-ratings';

const MyOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loader, setLoader] = useState(true);
    const [show, setShow] = useState(false);
    const [limit, setLimit] = useState(1);
    const [totalOrders, setTotalOrders] = useState(0);
    const [selectedStars, setSelectedStars] = useState(0);
    const [review, setReview] = useState('');
    const [orderId, setOrderId] = useState(0);

    const getOrders = async (limit) => {
        await getMyOrders(limit).then((response) => {
            setOrders(response.data.data.orders);
            setTotalOrders(response.data.data.count);
        }).catch((error) => {
            toast.error('Something went wrong', {
                theme: "light",
                position: "top-right"
            });
        });
        setLoader(false);
    };

    const handleOrders = async () => {
        const lmt = limit + 4;
        await getOrders(lmt);
        setLimit(lmt);
    }

    const handleClose = () => setShow(false);

    const giveReview = async () => {
      const payload = {
          rating: selectedStars,
          review: review,
          order_id: orderId
      }

      await saveReview(payload).then((response) => {
          toast.success(response.data.message);
          setSelectedStars(0);
          setReview('');
          setShow(false);
      })
    }

    useEffect(() => {
        if (orders.length === 0) {
            getOrders(1);
        }
    }, [orders]);

    return (

        loader ? (
            <Loader/>
        ) : (
            <Container fluid>
                <div className="dashboard-wrapper">
                    <div className="my-4 d-lg-none d-md-block">
                        <MobileNavbar/>
                    </div>
                    <Row>
                        <Sidebar activeTab='my-orders'/>
                        <Col lg={8} sm={12}>
                            <h5><strong>My Orders</strong></h5>
                            {
                                orders.length ?
                                    orders.map((order, index) => {
                                        return (
                                            <OrderCard order={order} key={index} setShow={setShow} setOrderId={setOrderId}/>
                                        )
                                    })
                                    : (
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="no-item-wrapper">
                                                    <div className="border-1">
                                                        <div className="border-2">
                                                            <img src={window.location.origin + "/img/no-item.png"} alt=""/>
                                                        </div>
                                                    </div>
                                                    <h4><strong>You no orders yet</strong></h4>
                                                    <Link to="/products" className="btn btn-info">Start Shopping</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                            {
                                orders.length && orders.length !== totalOrders ? (
                                    <>
                                        <div className="text-center mt-4">
                                            <button className="btn btn-outline-info" onClick={() => handleOrders()}>View More Orders</button>
                                        </div>
                                    </>
                                ) : ''
                            }
                        </Col>
                    </Row>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>Rate your order</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       <div className="text-center">
                           <h5>Please give your feedback on your order</h5>
                           <div className="stars">
                               <StarRatings
                                   rating={selectedStars}
                                   starDimension="40px"
                                   changeRating={(rating, name) => setSelectedStars(rating)}
                                   starSpacing="5px"
                                   starEmptyColor="#ddd"
                                   starHoverColor="#36499b"
                                   starRatedColor="#f9ba05"
                               />
                           </div>
                           <div className="text-area form-group">
                               <textarea className="form-control" rows="5"
                               placeholder="Write your review"
                               value={review} onChange={(e) => setReview(e.target.value)}/>
                           </div>
                       </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-outline-success" onClick={() => giveReview()}>
                            Done
                        </button>
                        <button className="btn btn-outline-info" onClick={handleClose}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    )
}

export default MyOrders;