/**
 * Calculate product price with discount
 * @param item
 * @returns {number|*}
 */
export function calculatePrice(item) {
    if (item?.apply_discount) {
        if (item?.discount_type === 'percentage') {
            let discounted_amount = (item?.price * item?.discount_value) / 100;
            discounted_amount = item?.price  - discounted_amount;
            return discounted_amount;
        }

        if (item?.discount_type === 'value') {
            return item?.price - item?.discount_value;
        }
    } else {
        return item?.price;
    }
}

/**
 * Filter items
 * @param items
 * @param filters
 * @returns {[]}
 */
export function filterItems(items, filters) {
    let filtered_items = [];
    console.log(items)
    filters.map((filter, index) => {
        filtered_items = items.filter((item) => {
            return item[filter.type] === filter.value;
        })
    });

    return filtered_items;
}

/**
 * Calculate Total
 * @param items
 * @param delivery_charges
 * @returns {{total: *, sub_total: number}}
 */
export function calculateTotal(items, delivery_charges) {
    let sub_total = 0;
    let total = 0;
    items.map((item, index) => {
       sub_total = sub_total + item.price;
    });

    return {
        sub_total: sub_total,
        total: sub_total + delivery_charges
    }
}