import React, {useEffect, useState} from "react";
import Header from "./Layout/Header";
import './App.css'
import Footer from "./Layout/Footer";
import {AppContext} from "./context/AppContext";
import {getSiteData} from "./requests";
import Loader from "./components/Loader";
import {useDispatch} from "react-redux";
import {setCart} from "./features/cart/cartSlice";
import APPRoutes from "./components/Routes";
import {AuthProvider} from "react-auth-kit";
import {getAddresses, getUserDetail, getWishList} from "./features/user/userSlice";

const App = () => {
    const dispatch = useDispatch();
    const [site_data, setSiteData] = useState({});
    const [loader, setLoader] = useState(false);
    const siteData = async () => {
        setLoader(true);
        await getSiteData().then((res) => {
            if (res.data.status_code === 200) {
                setSiteData(res.data.data);
            }
            setLoader(false);
        })
    };

    useEffect(() => {
        const __site_pass = JSON.parse(localStorage.getItem('__site_pass'));
        if (__site_pass) {
            const payload = {
                token: __site_pass.token,
                secure_pass: __site_pass.secure_pass
            };
            dispatch(getUserDetail(payload));
            dispatch(getAddresses(payload));
            dispatch(getWishList(payload));
        }

        if (Object.keys(site_data).length === 0) {
            siteData();
        }

        dispatch(setCart());
    }, []);
    return (
        <>
            {
                loader ? (
                    <Loader/>
                ) : (
                    <AuthProvider authType={'cookie'}
                                  authName={'_auth'}
                                  cookieDomain={window.location.hostname}
                                  cookieSecure={false}>
                        <AppContext.Provider value={{data: site_data}}>
                            <Header/>
                            <APPRoutes/>
                            <Footer/>
                        </AppContext.Provider>
                    </AuthProvider>
                )
            }
        </>
    );
};

export default App;
