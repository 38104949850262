import React, {useContext, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Sidebar from "./common/Sidebar";
import {getUserPointsHistory} from "../../requests";
import Loader from "../../components/Loader";
import {AppContext} from "../../context/AppContext";
import ReactPaginate from "react-paginate";
import MobileNavbar from "./common/MobileNavbar";

const PointsHistory = () => {
    const [history, setPointsHistory] = useState(null);
    const [loader, setLoader] = useState(true);
    const {data} = useContext(AppContext);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 10;

    const handlePageClick = (event) => {
        getPoints(event.selected + 1);
    };

    const getPoints = (page) => {
        getUserPointsHistory(page).then((response) => {
            setPointsHistory(response.data.data);
            setTotalPages(Math.ceil(response.data.data.total_records/limit).toFixed(0));
            setLoader(false);
        })
    };

    useEffect( () => {
        if (!history) {
            getPoints(1);
        }
    });

    return (
        <>
            {
                loader ?
                    <Loader/>
                    : (
                        <Container fluid>
                            <div className="dashboard-wrapper">
                                <div className="my-4 d-lg-none d-md-block">
                                    <MobileNavbar/>
                                </div>
                                <Row>
                                    <Sidebar activeTab="billu-points"/>
                                    <Col lg={8} sm={12}>
                                        <div className="dashboard-content">
                                            <h5>BilluClub Points History</h5>
                                            <div className="card card-border-primary mt-4">
                                                <div className="card-body">
                                                    <div className="billu-club-header">
                                                        <img src={window.location.origin + "/img/billuCLUB.png"} alt=""/>
                                                        {/*<div className="header-content">*/}
                                                        {/*    <p>Linked Card Number</p>*/}
                                                        {/*    <h5>98723655542</h5>*/}
                                                        {/*</div>*/}
                                                        <div className="header-content">
                                                            <p>Balance</p>
                                                            <h5>{history?.points} My ClubPoints ({data?.site_data?.amount_to_be_used_on_points * history?.points} RS)</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="history-table-header">
                                                <div>Transaction Date</div>
                                                <div>Points</div>
                                                <div>Status</div>
                                            </div>
                                            <div className="card">
                                                <div className="card-list">
                                                    <div className="card-body p-0">
                                                        {
                                                            history?.history.length ?
                                                                <div className="billu-club-table">
                                                                    {
                                                                        history.history.map((data, index) => {
                                                                            return (
                                                                                <div className="dashboard-table-item">
                                                                                    <div>{data.created_at}</div>
                                                                                    <div>{data.points}</div>
                                                                                    <div>{data.action.toUpperCase()} <FontAwesomeIcon icon={data.action === 'debit' ? faChevronDown : faChevronUp}
                                                                                                                                      className={data.action === 'debit' ? 'text-danger' : 'text-success'}/>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    <div className="my-4">
                                                                        <ReactPaginate
                                                                            breakLabel="..."
                                                                            nextLabel=">"
                                                                            onPageChange={handlePageClick}
                                                                            pageRangeDisplayed={1}
                                                                            pageCount={totalPages}
                                                                            previousLabel="<"
                                                                            renderOnZeroPageCount={null}
                                                                            className="react-pagination"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                : (
                                                                    <>
                                                                        <div className="text-center p-5">
                                                                            <img src={window.location.origin + "/img/database.svg"} alt="" width={50}/>
                                                                            <h4>You don't have any points available yet</h4>
                                                                        </div>
                                                                    </>
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    )
            }
        </>
    )
}

export default PointsHistory;