import React, {forwardRef, useContext, useEffect, useState} from "react";
import Loader from "../../components/Loader";
import {Row, Col, Modal, Container} from "react-bootstrap";
import Sidebar from "./common/Sidebar";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useSelector} from "react-redux";
import {faAt, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppContext} from "../../context/AppContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import {updateProfile} from "../../requests";
import {getUserDetail} from "../../features/user/userSlice";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import MobileNavbar from "./common/MobileNavbar";

const Profile = () => {
    const {data} = useContext(AppContext);
    const loader = false;
    const user_detail = useSelector((state) => state.userRedux.user);
    const [name, setName] = useState(user_detail?.name);
    const [email, setEmail] = useState(user_detail?.email);
    const [contact, setContact] = useState(user_detail?.profile?.contact_number);
    const [cnic, setCnic] = useState(user_detail?.profile?.national_id_card_number);
    const [show, setShow] = useState(false);
    const [dob, setDob] = useState(user_detail?.profile?.dob ? user_detail?.profile?.dob : new Date());
    const [userData, setUserData] = useState(0);
    const [btnLoader, setBtnLoader] = useState(false);
    const dispatch = useDispatch();

    const DatePickerComponent = forwardRef(({ value, onClick }, ref) => (
        <input type="text" name="dob" id="dob" className="form-control" onClick={onClick} ref={ref} style={{width: "100%"}} defaultValue={moment(dob).format('MM/DD/y')} />
    ));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const calculateProfileCompeletation = () => {
        let counter = 0;
        let each_count = 100 / 5;

        if (user_detail?.name) {
            counter = counter + each_count;
        }

        if (user_detail?.email) {
            counter = counter + each_count;
        }

        if (user_detail?.profile?.contact_number) {
            counter = counter + each_count;
        }

        if (user_detail?.profile?.dob) {
            counter = counter + each_count;
        }

        if (user_detail?.profile?.national_id_card_number) {
            counter = counter + each_count;
        }

        setUserData(counter);
    };

    const saveProfile = async () => {
        setBtnLoader(true);
            let payload = {
                "name": name,
                "email": email,
                "contact_number": contact,
                "national_id_card_number": cnic,
                "dob": dob
            };

            await updateProfile(payload).then((response) => {
                setBtnLoader(false);
                const __site_pass = JSON.parse(localStorage.getItem('__site_pass'));
                if (__site_pass) {
                    const payload = {
                        token: __site_pass.token,
                        secure_pass: __site_pass.secure_pass
                    };
                    dispatch(getUserDetail(payload));
                }
                handleClose();
                toast.success(response.data.message, {
                    theme: "light",
                    position: "top-right"
                })
            }).catch((error) => {
                toast.error("Something went wrong", {
                    theme: "light",
                    position: "top-right"
                })
            })
    };

    useEffect(() => {
        calculateProfileCompeletation()
    });

    return (
        <>
            {
                loader ? (
                    <Loader/>
                ) : (
                    <Container fluid>
                        <div className="dashboard-wrapper">
                            <div className="my-4 d-lg-none d-md-block">
                                <MobileNavbar/>
                            </div>
                            <Row>
                                <Sidebar activeTab="profile"/>
                                <Col lg={8} sm={12}>
                                    <div className="dashboard-content">
                                        <h5>My Profile</h5>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <ProgressBar variant="info" now={userData} label={`Completed ${userData}%`}/>
                                                <div className="profile-section mt-3">
                                                    <Row>
                                                        <Col sm={3}>
                                                            <h5>Account Information</h5>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <div className="profile-content">
                                                                <h5 className="m-0">{user_detail?.name}</h5>
                                                                <Row>
                                                                    <Col sm={6} className="border-right">
                                                                        <strong><FontAwesomeIcon icon={faAt}/> Email
                                                                            Address</strong><br/>
                                                                        <p>{user_detail?.email}</p>
                                                                    </Col>
                                                                    <Col sm={6}>
                                                                        <strong><FontAwesomeIcon icon={faPhone}/> Mobile
                                                                            Number</strong><br/>
                                                                        <p>{user_detail?.profile?.contact_number}</p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <div className="profile-edit-button">
                                                                <button className="btn btn-outline-info"
                                                                        onClick={handleShow}>Edit Profile
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <p className="m-0">Have a question? We are here to help. <strong
                                                    className="text-info float-right">{data?.site_data?.contact_number}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                )
            }
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mb-3 promo-code-wrap">
                        <label htmlFor="floatingInput">Name</label>
                        <input type="text" className="form-control" style={{width: "100%"}} id="floatingInput"
                               placeholder="Enter your full name" defaultValue={name}
                               onInput={(e) => setName(e.target.value)}/>
                    </div>
                    <div className="form-group mb-3 promo-code-wrap">
                        <label htmlFor="floatingInput">Email</label>
                        <input type="email" className="form-control" style={{width: "100%"}} id="floatingInput"
                               placeholder="Enter your email address" defaultValue={email}
                               onInput={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="form-group mb-3 promo-code-wrap">
                        <label htmlFor="floatingInput">CNIC</label>
                        <input type="email" className="form-control" style={{width: "100%"}} id="floatingInput"
                               placeholder="Enter your CNIC Number" defaultValue={cnic}
                               onInput={(e) => setCnic(e.target.value)}/>
                    </div>
                    <div className="form-group promo-code-wrap">
                        <label htmlFor="">Contact Number</label>
                        <PhoneInput
                            country={'pk'}
                            value={contact}
                            inputStyle={{width: "100%"}}
                            onChange={phone => setContact(phone)}
                        />
                    </div>
                    <div className="form-group mb-3 promo-code-wrap">
                        <label htmlFor="">Date of birth</label>
                        <DatePicker selected={dob} onSelect={(date) => {
                            setDob(date)}} customInput={<DatePickerComponent />} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-info" onClick={handleClose}>
                        Close
                    </button>
                    <button type="button" className="btn btn-info" disabled={btnLoader} onClick={() => saveProfile()}>
                        {
                            btnLoader ? (
                                <ClipLoader
                                    color="#fff"
                                    loading={true}
                                    size={20}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            ) : "Save"
                        }
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default Profile;