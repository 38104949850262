import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {addToWishlist, getProductDetail} from "../requests";
import Loader from "../components/Loader";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faLocationDot, faMotorcycle, faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {calculatePrice} from "../helpers/productsHelper";
// import parse from "html-react-parser";
// import Select from "react-select";
import ProductSlider from "../components/ProductSlider";
import {useIsAuthenticated} from "react-auth-kit";
import {addItemToWishList} from "../features/user/userSlice";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {addItem} from "../features/cart/cartSlice";
import Container from "react-bootstrap/Container";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const Product = () => {
    const {slug} = useParams();
    const [detail, setProductDetail] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [loader, setLoader] = useState(false);
    // const [options, setOptions] = useState();
    const [images, setImages] = useState([]);
    const isAuthenticated = useIsAuthenticated();
    const dispatch = useDispatch();

    const handleAddToCart = () => {
        dispatch(addItem({
            title: detail?.product_title,
            image: detail?.default_image,
            price: calculatePrice(detail),
            id: detail?.id
        }))
    };

    const setProductImages = (images) => {
        for (let i = 0; i < images.length; i++) {
            setImages(oldImages => [...oldImages, {
                original: process.env.REACT_APP_IMAGE_PATH + images[i].images,
                thumbnail: process.env.REACT_APP_IMAGE_PATH + images[i].images
            }])
        }
    }

    const productDetail = async () => {
        setLoader(true);
        await getProductDetail(slug).then((res) => {
            setProductDetail(res.data.data.product);
            if (res.data.data.product.images.length) {
                setProductImages(res.data.data.images);
            }
            setSimilarProducts(res.data.data.similar_products);
            // let options = [];
            // for (let i = 1; i <= res.data.data.product.quantity; i++) {
            //     options.push({value: i, label: i});
            // }
            // setOptions(options);
            let visitedProducts = localStorage.getItem('visited_products');
            visitedProducts = JSON.parse(visitedProducts);
            if (typeof visitedProducts === "undefined" || !visitedProducts) {
                let products = [];
                if (products.indexOf(res.data.data.product.id) === -1) {
                    products.push(res.data.data.product.id);
                    localStorage.setItem('visited_products', JSON.stringify(products));
                }
            } else {
                if (visitedProducts.indexOf(res.data.data.product.id) === -1) {
                    visitedProducts.push(res.data.data.product.id);
                    localStorage.setItem('visited_products', JSON.stringify(visitedProducts));
                }
            }
            setLoader(false);
        })
    };

    const handleWishList = async (id) => {
        const payload = {
            'product_id': id
        };

        await addToWishlist(payload).then((response) => {
            dispatch(addItemToWishList(response.data.data));
            toast.success(response.data.message, {
                theme: "light",
                position: "top-right"
            })
        }).catch((error) => {
            toast.error(error.response.data.message, {
                theme: "light",
                position: "top-right"
            })
        })
    }

    useEffect(() => {
        window.scroll(0, 0);
        if (!detail) {
            productDetail();
        }

    });

    return (
        <>
            {
                loader ?
                    <Loader/>
                    :
                    (
                        <div className="main-content">
                            {/* Shop Starts */}
                            <Container fluid>
                                <div className="breadcrumbs">
                                    <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </li>
                                        <li>{detail?.product_title}</li>
                                    </ul>
                                </div>
                                <div className="product-detail-section shadow">
                                    <Row>
                                        <Col lg={4} sm={12}>
                                            <div className="product-img-section">
                                                {
                                                    images.length > 0 ? (
                                                        <ImageGallery items={images} showFullscreenButton={false} showPlayButton={false}  />
                                                    ) : (
                                                        <img src={process.env.REACT_APP_IMAGE_PATH + detail?.default_image}
                                                             alt=""/>
                                                    )
                                                }
                                                {/*<div className="more-images">*/}
                                                {/*    <div className="img">*/}
                                                {/*        <img src="img/pro1.png" alt=""/>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="img">*/}
                                                {/*        <img src="img/pro1.png" alt=""/>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="img">*/}
                                                {/*        <img src="img/pro1.png" alt=""/>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="img">*/}
                                                {/*        <img src="img/pro1.png" alt=""/>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </Col>
                                        <Col lg={5} sm={12}>
                                            <div className="product-detail">
                                                {
                                                    detail?.category ? (
                                                        <p className="from-brand">
                                                            More from <Link to="/">{detail?.category?.category}</Link>
                                                        </p>
                                                    ) : ''
                                                }
                                                <h3>{detail?.product_title}</h3>
                                                <strong>{detail?.unit_value} {detail?.unit?.prefix}</strong>
                                                <h4>
                                                    Rs. {calculatePrice(detail)}&nbsp;
                                                    {
                                                        detail?.apply_discount ? (
                                                            <span className="text-danger">
                                                                <strike>Rs. {detail?.price}</strike> (inc VAT)
                                                            </span>
                                                        ) : ''
                                                    }
                                                </h4>
                                                <p className="delivery-bike">
                                                    <FontAwesomeIcon icon={faMotorcycle} className="text-info"/> Get it
                                                    by&nbsp;
                                                    <strong>within 60 mins</strong>
                                                </p>
                                                <p>
                                                    Sold and delivered by <strong>Billu Store</strong>
                                                </p>
                                                <p className="origin">
                                                      <span>
                                                         <FontAwesomeIcon icon={faLocationDot} className="text-info"/> Origin -{" "}
                                                      </span>
                                                    Pakistan
                                                </p>
                                                <h5>Highlights</h5>
                                                <p className="short-detail"
                                                   dangerouslySetInnerHTML={{__html: detail?.short_description}}>
                                                    {/*<span>{detail?.short_description ? parse(detail?.short_description) : ''}</span>*/}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <div className="add-cart-section">
                                                {/*<Select*/}
                                                {/*    options={options}*/}
                                                {/*    className="w-100 text-left qty"*/}
                                                {/*    containerClassName="qty"*/}
                                                {/*    placeholder="Select City"*/}
                                                {/*    defaultValue={defaultQty}*/}
                                                {/*    onChange={(e) => {*/}
                                                {/*        setDefaultQty(e);*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                                <a href="javascript:void(0);" onClick={() => handleAddToCart()}
                                                   className="btn btn-primary">
                                                    <FontAwesomeIcon icon={faShoppingCart}/>
                                                    &nbsp;&nbsp;&nbsp;Add to Cart
                                                </a>
                                            </div>
                                            <div className="product-other-options">
                                                {
                                                    isAuthenticated() ? (
                                                        <a href="javascript:void(0);" onClick={() => handleWishList(detail?.id)}>
                                                            <img src={window.location.origin + "/img/heart.svg"}
                                                                 alt=""/>
                                                            &nbsp;&nbsp;&nbsp;Add to Wishlist
                                                        </a>
                                                    ) : ''
                                                }
                                                {/*<a href="">*/}
                                                {/*    <i className="fa fa-share-alt"/>*/}
                                                {/*    &nbsp;&nbsp;&nbsp;Share*/}
                                                {/*</a>*/}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="px-xl-5">
                                        <Col sm={12}>
                                            <div className="product-long-desc">
                                                <h4>Description</h4>
                                                <div dangerouslySetInnerHTML={{__html: detail?.long_description}}>
                                                </div>
                                                <h4>Allergy Information</h4>
                                                <div dangerouslySetInnerHTML={{__html: detail?.allergy_info}}>
                                                </div>
                                                <h4>Storage Condition</h4>
                                                <div dangerouslySetInnerHTML={{__html: detail?.storage_info}}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="similar-products-section" id="discount-deals">
                                        <Row className="px-5">
                                            <Col sm={12}>
                                                <h3 className="underline-heading">
                                                    <span>Similar</span> Products
                                                </h3>
                                            </Col>
                                            {
                                                similarProducts.length ? (
                                                    <Col sm={12} className="mt-3">
                                                        <ProductSlider products={similarProducts} regular={true}
                                                                       recentItems={false}/>
                                                    </Col>
                                                ) : ''
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Container>
                            {/* Shop Ends */}
                        </div>

                    )
            }
        </>
    )
};

export default Product;