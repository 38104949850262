import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faLocationDot,
    faPen,
    faChevronDown,
    faPowerOff,
    faUser,
    faWallet,
    faBoxes,

    faArrowLeftRotate,
    faHeart
} from "@fortawesome/free-solid-svg-icons";
import {AppContext} from "../context/AppContext";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useSelector} from "react-redux";
import AuthModal from "../components/Models/AuthModal";
import {useIsAuthenticated} from 'react-auth-kit';
import {useSignOut} from 'react-auth-kit'
import Dropdown from 'react-bootstrap/Dropdown';
import AddressModal from "../components/Models/AddressModal";
import {getProducts, logout} from "../requests";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";
import {calculatePrice} from "../helpers/productsHelper";

const Header = () => {
    const {data} = useContext(AppContext);
    const [addressModal, setAddressModal] = useState(false);
    const cart_items = useSelector((state) => state.cart.items);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const isAuthenticated = useIsAuthenticated();
    const user_detail = useSelector((state) => state.userRedux);
    const user = user_detail.user;
    const wishList = user_detail.wishlist;
    const active_address = useSelector((state) => state.userRedux.active_address);
    const signOut = useSignOut();
    const query = null;
    // const [query, setQuery] = useState(null);
    const search = null;
    // const [search, setSearch] = useState(null);
    const history = useNavigate();
    const [activeMenu, setActiveMenu] = useState();
    const [activeSubMenus, setActiveSubMenus] = useState({});
    const [showRecommendedProducts, setShowRecommendedProducts] = useState(false);
    const [products, setProducts] = useState({});
    const [productListEnter, setProductListEnter] = useState(false);

    const handleAddressModalClose = () => {
        setAddressModal(false);
    };

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (Object.keys(params).length) {
            if (params.hasOwnProperty('login')) {
                setShowLoginModal(true);
            }
        }
    });

    const CustomToggle = React.forwardRef(({children, onClick}, ref) => (
        <a
            href=""
            ref={ref}
            className="cart-btn"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children} <FontAwesomeIcon icon={faChevronDown}/>
        </a>
    ));

    const handleProductSearch = () => {
        history('/products?q=' + query);
    };

    const userLogout = async () => {
        await logout().then((response) => {
            localStorage.removeItem('__site_pass');
            signOut();
        }).catch((error) => {
            console.log(error)
            toast.error("Something went wrong", {
                theme: "light",
                position: "top-right"
            })
        })
    };

    const getProductsLists = (value) => {
        getProducts(1, value).then((res) => {
            setProducts(res.data.data.products);
        });
    }

    const handleNavigate = (slug) => {
        console.log(slug)
        window.location.replace(`/product/${slug}`)
        // setShowRecommendedProducts(false);
    }

    return (
        <>
            {/* Topbar Start */}
            <div className="header-bar">
                <Container fluid>
                    <Row className="top-bar py-1 px-xl-5">
                        <Col md={6} sm={6}>
                            <div className="d-flex justify-content-lg-around align-items-center h-100">
                                <p>
                                    <img src={window.location.origin + "/img/fast-delivery.svg"} alt=""/>
                                    &nbsp;&nbsp;Fast Delivery
                                </p>
                                <p className="d-none d-lg-block">
                                    <img src={window.location.origin + "/img/shipping-allover.svg"} alt=""/>
                                    &nbsp;&nbsp;Shipping over 15,000 products
                                </p>
                                <p className="d-none d-lg-block">
                                    <img src={window.location.origin + "/img/free-return.svg"} alt=""/>
                                    &nbsp;&nbsp;Free Returns
                                </p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} className="text-center text-lg-right">
                            <div className="d-flex align-items-center justify-content-end">
                                <p>
                                    <img src={window.location.origin + "/img/phone.svg"} alt=""/>
                                    &nbsp;&nbsp;{data?.site_data?.contact_number}
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center bg-light py-0 px-5 d-none d-lg-flex">
                        <Col lg={3}>
                            <Link to="/" className="text-decoration-none">
                                <img src={process.env.REACT_APP_IMAGE_PATH + data?.site_data?.logo} alt=""
                                     className="logo"/>
                            </Link>
                        </Col>
                        <Col lg={6}>
                            <div className="input-group search-bar">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search for products"
                                    defaultValue={query}
                                    onFocus={(e) => {
                                        setShowRecommendedProducts(true)
                                    }}
                                    onBlur={() => {
                                        if (!productListEnter && search) {
                                            setShowRecommendedProducts(false)
                                        }
                                    }}
                                    onChange={(e) => {
                                        setShowRecommendedProducts(true)
                                        getProductsLists(e.target.value);
                                    }}
                                />
                                <div className="input-group-append">
                                    <button className="btn input-group-text" onClick={() => handleProductSearch()}>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </button>
                                </div>
                                {
                                    showRecommendedProducts && products.length ? (
                                        <div className="search-result-list"
                                             onMouseEnter={() => setProductListEnter(true)}
                                             onMouseLeave={() => setShowRecommendedProducts(false)}>
                                            <ul>
                                                {
                                                    products.length ?
                                                        products.map((product, index) => {
                                                            return (
                                                                <li key={index}
                                                                    onClick={() => handleNavigate(product.slug)}>
                                                                    {/*<Link to={`/product/${product.slug}`}>*/}
                                                                    <Row>
                                                                        <Col m={2}>
                                                                            <img
                                                                                src={process.env.REACT_APP_IMAGE_PATH + product?.default_image}
                                                                                width={50} alt=""/>
                                                                        </Col>
                                                                        <Col sm={10}>
                                                                            <h5>{product.product_title}</h5>
                                                                            <p className="text-info unit">Rs.{calculatePrice(product)}</p>
                                                                        </Col>
                                                                    </Row>
                                                                    {/*</Link>*/}
                                                                </li>
                                                            )
                                                        }) : ''
                                                }
                                            </ul>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        </Col>
                        <Col lg={3} className="text-right">
                            <div className="header-actions-buttons">
                                {
                                    isAuthenticated() ? (
                                        <>
                                            <Dropdown>
                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                                                    {
                                                        user.name
                                                    }
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="/profile"><FontAwesomeIcon className="mr-3"
                                                                                                    icon={faUser}/> Profile</Dropdown.Item>
                                                    <Dropdown.Item href="/billu-club"><FontAwesomeIcon className="mr-3"
                                                                                                       icon={faWallet}/> BilluPoints</Dropdown.Item>
                                                    <Dropdown.Item href="/my-orders"><FontAwesomeIcon className="mr-3"
                                                                                                      icon={faBoxes}/> My
                                                        Orders</Dropdown.Item>
                                                    <Dropdown.Item href="/my-returns"><FontAwesomeIcon className="mr-3"
                                                                                                       icon={faArrowLeftRotate}/> My
                                                        Returns</Dropdown.Item>
                                                    <Dropdown.Item href="/my-wishlist"><FontAwesomeIcon className="mr-3"
                                                                                                        icon={faHeart}/> My
                                                        Wish list</Dropdown.Item>
                                                    <Dropdown.Divider/>
                                                    <Dropdown.Item
                                                        onClick={() => userLogout()}
                                                    ><FontAwesomeIcon icon={faPowerOff}/> Logout</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Link to="/my-wishlist" className="wishlist-btn">
                                                <img src={window.location.origin + "/img/heart.svg"} alt=""/>
                                                &nbsp; {wishList.length}
                                            </Link>
                                        </>
                                    ) : (
                                        <span
                                            className="text-dark login-sign-up-btn"
                                            onClick={() => setShowLoginModal(true)}
                                        >
                                            <img src={window.location.origin + "/img/user.svg"} alt=""/>
                                            &nbsp;&nbsp; Login &amp; Register
                                        </span>
                                    )
                                }
                                <Link to="/cart" className="cart-btn">
                                    <img src={window.location.origin + "/img/cart.svg"} alt=""/>
                                    &nbsp; {cart_items.length}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/*<Navbar variant="light" className="shadow" bg="light" expand="lg">*/}
                {/*    <Container fluid>*/}
                {/*        <Navbar.Brand href="/" className="d-lg-none d-md-block">*/}
                {/*            <img src={process.env.REACT_APP_IMAGE_PATH + data?.site_data?.logo}*/}
                {/*                 alt="logo" width={125}/>*/}
                {/*        </Navbar.Brand>*/}
                {/*        <Navbar.Toggle aria-controls="navbar-light-example"/>*/}
                {/*        <Navbar.Collapse id="navbar-light-example">*/}
                {/*            <Row className="w-100 px-xl-5">*/}
                {/*                <Col lg={8} sm={12}>*/}
                {/*                    <Nav>*/}
                {/*                        <NavDropdown*/}
                {/*                            id="nav-dropdown-dark-example"*/}
                {/*                            title="All Categories"*/}
                {/*                            menuVariant="light"*/}
                {/*                            className="allCategoriesDropdown d-none d-lg-block"*/}
                {/*                        >*/}
                {/*                            {*/}
                {/*                                typeof data?.categories !== 'undefined' &&*/}
                {/*                                data?.categories.map((category, index) => {*/}
                {/*                                    return <NavDropdown.Item key={index}*/}
                {/*                                                             href={window.location.origin + '/categories/' + category?.slug}>*/}
                {/*                                        {category?.category}*/}
                {/*                                    </NavDropdown.Item>*/}
                {/*                                })*/}
                {/*                            }*/}
                {/*                        </NavDropdown>*/}
                {/*                        {*/}
                {/*                            typeof data?.navigation_menus !== 'undefined' &&*/}
                {/*                            data?.navigation_menus.map((category, index) => {*/}
                {/*                                return <Nav.Link key={index}*/}
                {/*                                                 href={window.location.origin + '/categories/' + category?.slug}>{category?.category}</Nav.Link>*/}
                {/*                            })*/}
                {/*                        }*/}
                {/*                        {*/}
                {/*                            isAuthenticated() ? (*/}
                {/*                                <>*/}
                {/*                                    <Nav.Item className="d-lg-none d-sm-block">*/}
                {/*                                      <span className="btn px-0" onClick={() => setAddressModal(true)}*/}
                {/*                                            style={{fontSize: 13}}>*/}
                {/*                                        <FontAwesomeIcon icon={faLocationDot}*/}
                {/*                                                         className="text-info"/>&nbsp;*/}
                {/*                                          <span*/}
                {/*                                              style={{paddingBottom: 2}}*/}
                {/*                                          >*/}
                {/*                                             {active_address ? (*/}
                {/*                                                 <>*/}
                {/*                                                     Deliver to: <strong>*/}
                {/*                                                     {active_address.address}&nbsp;*/}
                {/*                                                     <FontAwesomeIcon icon={faPen}/>*/}
                {/*                                                 </strong>*/}
                {/*                                                 </>*/}
                {/*                                             ) : (*/}
                {/*                                                 <strong>No Active Address <FontAwesomeIcon*/}
                {/*                                                     icon={faPen}/></strong>*/}
                {/*                                             )}*/}
                {/*                                            </span>*/}
                {/*                                        </span>*/}
                {/*                                    </Nav.Item>*/}
                {/*                                </>*/}
                {/*                            ) : ''*/}
                {/*                        }*/}
                {/*                    </Nav>*/}
                {/*                </Col>*/}
                {/*                <Col lg={4} sm={12}*/}
                {/*                     className="d-none d-lg-flex align-items-center justify-content-end">*/}
                {/*                    {*/}
                {/*                        isAuthenticated() ? (*/}
                {/*                            <>*/}
                {/*                            <span className="btn px-0" onClick={() => setAddressModal(true)}*/}
                {/*                                  style={{fontSize: 13}}>*/}
                {/*                                <FontAwesomeIcon icon={faLocationDot} className="text-info"/>&nbsp;*/}
                {/*                                <span*/}
                {/*                                    style={{paddingBottom: 2}}*/}
                {/*                                >*/}
                {/*                                       {active_address ? (*/}
                {/*                                           <>*/}
                {/*                                               Deliver to: <strong>*/}
                {/*                                               {active_address.address}&nbsp;*/}
                {/*                                               <FontAwesomeIcon icon={faPen}/>*/}
                {/*                                           </strong>*/}
                {/*                                           </>*/}
                {/*                                       ) : (*/}
                {/*                                           <strong>No Active Address <FontAwesomeIcon*/}
                {/*                                               icon={faPen}/></strong>*/}
                {/*                                       )}*/}
                {/*                                    </span>*/}
                {/*                            </span>*/}
                {/*                            </>*/}
                {/*                        ) : ''*/}
                {/*                    }*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Navbar.Collapse>*/}
                {/*    </Container>*/}
                {/*    <Container fluid className=" d-lg-none d-md-block">*/}
                {/*        <div className="mobile-header">*/}
                {/*            <div className="auth">*/}
                {/*                {*/}
                {/*                    isAuthenticated() ? (*/}
                {/*                        <Link*/}
                {/*                            className="text-dark login-sign-up-btn"*/}
                {/*                            to="/profile"*/}
                {/*                        >*/}
                {/*                            <img src={window.location.origin + "/img/user.svg"} alt=""/>*/}
                {/*                            &nbsp;&nbsp; {user.name}*/}
                {/*                        </Link>*/}
                {/*                    ) : (*/}
                {/*                        <span*/}
                {/*                            className="text-dark login-sign-up-btn"*/}
                {/*                            onClick={() => setShowLoginModal(true)}*/}
                {/*                        >*/}
                {/*                            <img src={window.location.origin + "/img/user.svg"} alt=""/>*/}
                {/*                            &nbsp;&nbsp; Login &amp; Register*/}
                {/*                        </span>*/}
                {/*                    )*/}
                {/*                }*/}

                {/*            </div>*/}
                {/*            <div className="users-btns">*/}
                {/*                {*/}
                {/*                    isAuthenticated() ? (*/}
                {/*                        <Link to="/my-wishlist" className="wishlist-btn">*/}
                {/*                            <img src={window.location.origin + "/img/heart.svg"} alt=""/>*/}
                {/*                            &nbsp; {wishList.length}*/}
                {/*                        </Link>*/}
                {/*                    ) : ''*/}
                {/*                }*/}
                {/*                <Link to="/cart" className="cart-btn">*/}
                {/*                    <img src={window.location.origin + "/img/cart.svg"} alt=""/>*/}
                {/*                    &nbsp; {cart_items.length}*/}
                {/*                </Link>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </Container>*/}
                {/*</Navbar>*/}
                <Navbar variant="light" className="shadow position-relative" bg="light" expand="lg">
                    <Container fluid>
                        <Navbar.Brand href="/" className="d-lg-none d-md-block">
                            <img src={process.env.REACT_APP_IMAGE_PATH + data?.site_data?.logo}
                                 alt="logo" width={125}/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-light-example"/>
                        <Navbar.Collapse id="navbar-light-example">
                            <Row className="w-100 px-xl-5">
                                <Col lg={8} sm={12}>
                                    <Nav>
                                        <Nav.Link href="javascript:void(0);" onMouseEnter={() => {
                                            setActiveMenu('all_categories')
                                        }} className="all_categories_menu">All Categories</Nav.Link>
                                        {
                                            typeof data?.categories !== 'undefined' &&
                                            data?.categories.map((category, index) => {
                                                return <Nav.Link key={index}
                                                                 href="javascript:void(0);" onMouseEnter={() => {
                                                    setActiveMenu(category?.slug + '_' + index);
                                                    setActiveSubMenus(category?.sub_categories);
                                                }}>{category?.category}</Nav.Link>
                                            })
                                        }
                                        {
                                            isAuthenticated() ? (
                                                <>
                                                    <Nav.Item className="d-lg-none d-sm-block">
                                                      <span className="btn px-0" onClick={() => setAddressModal(true)}
                                                            style={{fontSize: 13}}>
                                                        <FontAwesomeIcon icon={faLocationDot}
                                                                         className="text-info"/>&nbsp;
                                                          <span
                                                              style={{paddingBottom: 2}}
                                                          >
                                                             {active_address ? (
                                                                 <>
                                                                     Deliver to: <strong>
                                                                     {active_address.address}&nbsp;
                                                                     <FontAwesomeIcon icon={faPen}/>
                                                                 </strong>
                                                                 </>
                                                             ) : (
                                                                 <strong>No Active Address <FontAwesomeIcon
                                                                     icon={faPen}/></strong>
                                                             )}
                                                            </span>
                                                        </span>
                                                    </Nav.Item>
                                                </>
                                            ) : ''
                                        }
                                    </Nav>
                                </Col>
                                <Col lg={4} sm={12}
                                     className="d-none d-lg-flex align-items-center justify-content-end">
                                    {
                                        isAuthenticated() ? (
                                            <>
                                            <span className="btn px-0" onClick={() => setAddressModal(true)}
                                                  style={{fontSize: 13}}>
                                                <FontAwesomeIcon icon={faLocationDot} className="text-info"/>&nbsp;
                                                <span
                                                    style={{paddingBottom: 2}}
                                                >
                                                       {active_address ? (
                                                           <>
                                                               Deliver to: <strong>
                                                               {active_address.address}&nbsp;
                                                               <FontAwesomeIcon icon={faPen}/>
                                                           </strong>
                                                           </>
                                                       ) : (
                                                           <strong>No Active Address <FontAwesomeIcon
                                                               icon={faPen}/></strong>
                                                       )}
                                                    </span>
                                            </span>
                                            </>
                                        ) : ''
                                    }
                                </Col>
                            </Row>
                            {
                                activeMenu === 'all_categories' && (
                                    <div className="navbar_dropdown shadow">
                                        <Row>
                                            <Col sm={3} className="pr-0">
                                                <div className="all_categories_sidebar">
                                                    {
                                                        data?.categories?.length ?
                                                            data?.categories.map((item, index) => {
                                                                return (<span onMouseEnter={() => {
                                                                    setActiveSubMenus(item.sub_categories)
                                                                }} key={index}>{item.category}</span>)
                                                            }) : ''
                                                    }
                                                </div>
                                            </Col>
                                            <Col sm={9} className="pl-0">
                                                <div className="mega_menus" onMouseLeave={() => setActiveMenu(null)}>
                                                    {
                                                        activeSubMenus.length ?
                                                            activeSubMenus.map((menu, index) => {
                                                                return (
                                                                    <div key={index} className="menus">
                                                                        <h5>{menu.category}</h5>
                                                                        {
                                                                            menu?.sub_categories.length ?
                                                                                menu?.sub_categories.map((subcategory, index) => {
                                                                                    return (<Link
                                                                                        href={window.location.origin + '/categories/' + subcategory?.slug}
                                                                                        key={index}>{subcategory.category}</Link>)
                                                                                }) : ''
                                                                        }
                                                                    </div>
                                                                )
                                                            }) : ''
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }
                            {
                                data?.categories?.map((category, index) => {
                                    return (
                                        activeMenu === category?.slug + '_' + index ?
                                            <div className="navbar_dropdown shadow">
                                                <Row>
                                                    <Col sm={12}>
                                                        <div className="mega_menus"
                                                             onMouseLeave={() => setActiveMenu(null)}>
                                                            {
                                                                activeSubMenus.length ?
                                                                    activeSubMenus.map((menu, index) => {
                                                                        return (
                                                                            <div key={index} className="menus">
                                                                                <h5>{menu.category}</h5>
                                                                                {
                                                                                    menu?.sub_categories.length ?
                                                                                        menu?.sub_categories.map((subcategory, index) => {
                                                                                            return (
                                                                                                <a href={window.location.origin + '/categories/' + subcategory?.slug}
                                                                                                   key={index}>{subcategory.category}</a>)
                                                                                        }) : ''
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }) : ''
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            : ''
                                    )
                                })
                            }
                        </Navbar.Collapse>
                    </Container>
                    <Container fluid className=" d-lg-none d-md-block">
                        <div className="mobile-header">
                            <div className="auth">
                                {
                                    isAuthenticated() ? (
                                        <Link
                                            className="text-dark login-sign-up-btn"
                                            to="/profile"
                                        >
                                            <img src={window.location.origin + "/img/user.svg"} alt=""/>
                                            &nbsp;&nbsp; {user.name}
                                        </Link>
                                    ) : (
                                        <span
                                            className="text-dark login-sign-up-btn"
                                            onClick={() => setShowLoginModal(true)}
                                        >
                                            <img src={window.location.origin + "/img/user.svg"} alt=""/>
                                            &nbsp;&nbsp; Login &amp; Register
                                        </span>
                                    )
                                }

                            </div>
                            <div className="users-btns">
                                {
                                    isAuthenticated() ? (
                                        <Link to="/my-wishlist" className="wishlist-btn">
                                            <img src={window.location.origin + "/img/heart.svg"} alt=""/>
                                            &nbsp; {wishList.length}
                                        </Link>
                                    ) : ''
                                }
                                <Link to="/cart" className="cart-btn">
                                    <img src={window.location.origin + "/img/cart.svg"} alt=""/>
                                    &nbsp; {cart_items.length}
                                </Link>
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </div>
            {/* Topbar End */}

            <AddressModal
                addressModal={addressModal}
                handleAddressModalClose={handleAddressModalClose}
                address_obj={active_address}
            />

            {
                showLoginModal && !isAuthenticated() ? (
                    <AuthModal
                        setShowLoginModal={setShowLoginModal}
                        showLoginModal={showLoginModal}
                        data={data}
                    />
                ) : ''
            }
            <Helmet>
                <link rel="icon" href={process.env.REACT_APP_IMAGE_PATH + data?.site_data?.favicon}/>
            </Helmet>
        </>
    )
};

export default Header;