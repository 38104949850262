import React, {useEffect, useState} from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {GoogleMap, LoadScript, Marker, MarkerF} from '@react-google-maps/api';
import Select from "react-select";
import {addUserAddress, getCities} from "../../requests";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {addAddressToObject} from "../../features/user/userSlice";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Geocode from "react-geocode";

const containerStyle = {
    width: '100%',
    height: '400px'
};

const AddressModal = (props) => {
    const {addressModal, handleAddressModalClose, address_obj} = props;
    const [address, setAddress] = useState(address_obj ? address_obj.address : '');
    const [receiver_name, setReceiverName] = useState(address_obj ? address_obj.receiver_name : '');
    const [contact_number, setReceiverContact] = useState(address_obj ? address_obj.contact_number : '');
    const [deliveryInfo, setDeliveryInfo] = useState(address_obj ? (address_obj.delivery_instruction ? address_obj.delivery_instruction : null) : null);
    const [activeLabel, setActiveLabel] = useState(address_obj ? (address_obj.labels ? address_obj.labels : 'home') : 'home');
    const [options, setOptions] = useState(null);
    const [selectedCity, setSelectedCity] = useState(address_obj ? JSON.parse(address_obj.city_obj) : {
        value: null,
        label: "Select City"
    });
    const {register, handleSubmit, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const [lat, setLat] = useState(31.4475);
    const [lng, setLng] = useState(74.3081);
    const [position, setPosition] = useState({
        lat: lat,
        lng: lng
    });
    const [addressSearch, setAddressSearch] = useState('');

    const getCitiesForSignup = async () => {
        await getCities().then((res) => {
            setOptions(res?.data?.data?.cities)
        })
    };

    const updateAddress = () => {
        Geocode.setApiKey("AIzaSyDeUeV8aSk-NF3Mcl5EO5hWxlA6P0Yykxc");
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                let address = response.results[0].formatted_address;
                let city;
                for (let i = 0; i < response.results[0].address_components.length; i++) {
                    for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                        if (response.results[0].address_components[i].types[j] === "locality") {
                            city = response.results[0].address_components[i].long_name;
                        }
                    }
                }
                if (options) {
                    options.map((item) => {
                        if (item.label.toLowerCase() === city.toLowerCase()) {
                            setSelectedCity(item)
                        }
                    });
                    setAddress(address);
                }
            },
            (error) => {
                console.error(error);
            }
        );
    }

    const addAddress = (data) => {
        const payload = data;
        payload.city_id = selectedCity.value;
        payload.city_obj = JSON.stringify(selectedCity);
        payload.contact_number = contact_number;
        payload.labels = activeLabel;
        if (deliveryInfo) {
            payload.delivery_instruction = deliveryInfo;
        }

        addUserAddress(payload).then((response) => {
            dispatch(addAddressToObject(response.data.address));
            handleAddressModalClose();
            toast.success(response.data.message, {
                theme: "light",
                position: "top-right"
            });
        })
    };

    const handleMarkerPosition = (e) => {
        setLat(e.latLng.lat());
        setLng(e.latLng.lng());
        setPosition({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        });
        updateAddress();
    };

    const handleAddressSearch = (e) => {
        setAddressSearch(e.target.value);
        Geocode.fromAddress(e.target.value).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setLat(lat);
                setLng(lng);
                setPosition({
                    lat: lat,
                    lng: lng
                });

                updateAddress();
            },
            (error) => {
                console.error(error);
            }
        );
    }

    useEffect(() => {
        if (!options) {
            getCitiesForSignup()
        }
        updateAddress();
    });

    return (
        <Offcanvas placement="end" show={addressModal} onHide={handleAddressModalClose}>
            <Offcanvas.Header>
                <Offcanvas.Title>
                    <Row>
                        <Col sm={10}>
                            <h5> Please Add Delivery Address</h5>
                        </Col>
                        <Col sm={2} className="text-right">
                            <span onClick={handleAddressModalClose}>
                                 <FontAwesomeIcon icon={faTimes}/>
                            </span>
                        </Col>
                    </Row>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <form onSubmit={handleSubmit(addAddress)}>
                    <Row>
                        <Col sm={12}>
                            <div className="input-group search-bar">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Location"
                                    defaultValue={addressSearch}
                                    onChange={(e) => handleAddressSearch(e)}
                                />
                                <div className="input-group-append">
                                    <button className="btn input-group-text">
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col className="mt-3" sm={12}>
                            <LoadScript
                                googleMapsApiKey="AIzaSyDeUeV8aSk-NF3Mcl5EO5hWxlA6P0Yykxc"
                            >
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    zoom={16}
                                    center={position}
                                >
                                    <MarkerF
                                        draggable
                                        onDragEnd={(e) => {handleMarkerPosition(e)}}
                                        position={position}
                                    />
                                </GoogleMap>
                            </LoadScript>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <h5>Address Information</h5>
                        </Col>
                        <Col className="mt-3" sm={12}>
                            <div className="form-group">
                                <label>Address</label>
                                <input type="text" className="form-control" value={address} placeholder="Enter Address"
                                       onInput={(e) => setAddress(e.target.value)}
                                       {
                                           ...register('address',
                                               {
                                                   required: {
                                                       value: true,
                                                       message: 'Address is required'
                                                   }
                                               })
                                       }
                                />
                                {
                                    errors?.address && (<span
                                        className="text-danger">{errors?.address?.message}</span>)
                                }
                            </div>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <div className="form-group">
                                <label>City</label>
                                <Select
                                    options={options}
                                    className="w-100 text-left"
                                    placeholder="Select City"
                                    value={selectedCity}
                                    onChange={(e) => {
                                        setSelectedCity(e);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col className="mt-3" sm={12}>
                            <div className="form-group">
                                <label>Delivery Instruction (Optional)</label>
                                <textarea className="form-control" rows="5"
                                          placeholder="Delivery Instruction (Optional)"
                                          value={deliveryInfo} onChange={(e) => setDeliveryInfo(e.target.value)}/>
                            </div>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <div className="map-labels">
                                <div className={`label ${activeLabel === 'home' ? 'active' : ''}`} onClick={() => setActiveLabel('home')}>
                                    Home
                                </div>
                                <div className={`label ${activeLabel === 'office' ? 'active' : ''}`} onClick={() => setActiveLabel('office')}>
                                    Office
                                </div>
                                <div className={`label ${activeLabel === 'other' ? 'active' : ''}`} onClick={() => setActiveLabel('other')}>
                                    Other
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <h5>Receiver Info</h5>
                        </Col>
                        <Col sm={12} className="mt-3">
                            <input type="text" className="form-control" value={receiver_name} onInput={(e) => setReceiverName(e.target.value)}
                                   {
                                       ...register('receiver_name',
                                           {
                                               required: {
                                                   value: true,
                                                   message: 'Receiver Info is required'
                                               }
                                           })
                                   }
                            />
                            {
                                errors?.receiver_name && (<span
                                    className="text-danger">{errors?.receiver_name?.message}</span>)
                            }
                        </Col>
                        <Col sm={12} className="my-3">
                            <PhoneInput
                                inputStyle={{width: "100%"}}
                                country={'pk'}
                                value={contact_number}
                                onChange={phone => setReceiverContact(phone)}
                            />
                        </Col>
                    </Row>
                    <button className="btn btn-info btn-block" type="submit">Add Address</button>
                </form>
            </Offcanvas.Body>
        </Offcanvas>
    )
};

export default AddressModal;