import React, {useEffect, useState} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {calculatePrice} from "../../helpers/productsHelper";
import RegularProduct from "../RegularProduct";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const responsive = {
    desktop: {
        breakpoint: {
            max: 3000,
            min: 1024
        },
        items: 4,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: {
            max: 464,
            min: 0
        },
        items: 1,
        partialVisibilityGutter: 30
    },
    tablet: {
        breakpoint: {
            max: 1024,
            min: 464
        },
        items: 2,
        partialVisibilityGutter: 30
    }
};

const ProductSlider = (props) => {
    const {products, regular, recentItems} = props;
    const [cssClass, setCssClass] = useState(`container-with-dots`);

    useEffect(() => {
        let css = `container-with-dots`;
        if (recentItems) {
            css = `container-with-dots recent-carousel`;
        }
        setCssClass(css);
    })


    return (
        <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass={`container-with-dots`}
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
        >
            {
                products.map((product, index) => {
                    return (

                        regular ? (
                            <span key={index}>
                                <RegularProduct product={product}/>
                            </span>
                            ) :
                            recentItems ? (
                                <div key={index} className="recent-product">
                                    <Row>
                                        <Col sm={6}>
                                            <img src={process.env.REACT_APP_IMAGE_PATH + product?.default_image}
                                                 className="img-fluid" alt="" width={250} height={250}/>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="detail">
                                                <h5>{product?.product_title}</h5>
                                                <p className="item-price">
                                                    <span>Rs.{calculatePrice(product)}</span>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div key={index}>
                                    <a href={`/product/${product.slug}`}>
                                        <div className="thumb-wrapper">
                                            <div className="img-box">
                                                <img src={process.env.REACT_APP_IMAGE_PATH + product?.default_image}
                                                     className="img-fluid" alt=""/>
                                            </div>
                                            <div className="thumb-content">
                                                <h4>{product?.product_title}</h4>
                                                <p>{product?.unit_value} {product?.unit?.prefix}</p>
                                                <p className="item-price">
                                                    {
                                                        product?.apply_discount ?
                                                            <strike>Rs.{product?.price} </strike> : ''
                                                    }
                                                    <span>Rs.{calculatePrice(product)}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                    )
                })
            }
        </Carousel>
    )
};

export default ProductSlider;