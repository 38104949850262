import React, {useContext, useEffect, useState} from "react";
import {getHomeFeeds} from "../requests";
import Container from "react-bootstrap/Container";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AppContext} from "../context/AppContext";
import {Link} from "react-router-dom";
import DiscountItemCard from "../components/DiscountProductCard";
import Banners from "../components/Banner";
import CategoryCard from "../components/CategoryCard";
import ProductSlider from "../components/ProductSlider";
import RegularProduct from "../components/RegularProduct";
import Loader from "../components/Loader";
import {Carousel} from "react-responsive-carousel";

const Home = () => {
    const [homeData, setHomeData] = useState({});
    const {data} = useContext(AppContext);
    const [loader, setLoader] = useState(true);

    const getData = async () => {
        await getHomeFeeds().then((res) => {
            setHomeData(res.data.data);
            setLoader(false);
        })
    };

    const renderStars = (rating) => {
      let html = '';
        for (let i = 1; i <= 5; i++) {
            if (rating <= i) {
                html += '<img alt="" src="/img/star.svg" width="20" height="20"/>'
            } else {
                html += '<img alt="" src="/img/star-filled.svg" width="20" height="20"/>'
            }
        }

        return html;
    }

    useEffect(() => {
        window.scroll(0,0);

        if (Object.keys(homeData).length === 0) {
            getData();
        }
    }, [homeData]);

    return (
        <>
            {
                loader ? (
                   <Loader/>
                ) : (
                    <div className="main-content">
                        <section id="banner-carousel">
                            <Container fluid className="mb-3">
                                <Row className="px-xl-5">
                                    <Col>
                                        <Banners banners={data?.site_data?.banners} />
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {/* Carousel End */}
                        {/* Browse by categories */}
                        <section id="categories" className="mt-4">
                            <Container fluid>
                                <Row className="px-xl-5">
                                    <Col sm={12}>
                                        <h3 className="underline-heading">
                                            <span>Browse</span> by Category
                                        </h3>
                                    </Col>
                                    <Col sm={12}>
                                        <Row>
                                            {
                                                typeof homeData?.categories !== 'undefined' &&
                                                homeData?.categories.map((category, index) => {
                                                    return (
                                                        <Col lg={2} md={6} sm={12} key={index}>
                                                            <CategoryCard category={category} />
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {/* Browse by categories end */}
                        {/* Image Section */}
                        <section id="image-section-1">
                            <Container fluid className="px-xl-5">
                                <div
                                    className="image-section"
                                    style={{backgroundImage: 'url("img/banner2.jpg")'}}
                                />
                            </Container>
                        </section>
                        {/* Image Section End */}
                        {/* Discount Deals */}
                        <section id="discount-deals">
                            <Container fluid className="my-1">
                                <Row className="px-xl-5">
                                    <Col sm={6}>
                                        <h3 className="underline-heading">
                                            <span>Discount</span> Deals
                                        </h3>
                                    </Col>
                                    <Col sm={6} className="text-right">
                                        <Link to="/products" className="section-links">
                                            View all
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className="m-xl-5 mx-1 my-3 px-xl-5 discount-items">
                                    {
                                        typeof homeData?.discounted_products !== "undefined" ? (
                                            homeData?.discounted_products.map((item, index) => {
                                                return (
                                                    <Col lg={4} sm={12} className="my-3" key={index}>
                                                        <DiscountItemCard item={item} />
                                                    </Col>
                                                )
                                            })
                                        ) : (
                                            <Col sm={12} className="text-center">
                                                <h5 className="text-dark">No Products</h5>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Container>
                        </section>
                        {/* Discount Deals Ends */}
                        {/* Fruits and Vegetables */}
                        {
                           typeof homeData?.fruits_vegetables_products?.products !== "undefined" ? (
                               <section id="fruits_vegetables">
                                   <Container fluid className="my-5">
                                       <Row className="px-xl-5">
                                           <Col sm={12}>
                                               <h3 className="underline-heading">
                                                   <span>Fruits</span> and Vegetables
                                               </h3>
                                           </Col>
                                       </Row>
                                       <Row className="px-xl-5">
                                           <Col sm={12}>
                                               {
                                                   typeof homeData?.fruits_vegetables_products?.products !== "undefined" ? (
                                                       <ProductSlider products={homeData?.fruits_vegetables_products?.products} regular={false} />
                                                   ) : (
                                                       <Col sm={12} className="text-center">
                                                           <h5 className="text-dark">No Products</h5>
                                                       </Col>
                                                   )
                                               }
                                           </Col>
                                       </Row>
                                   </Container>
                               </section>
                           ) : ''
                        }
                        {/* Fruits and Vegetables Ends*/}
                        {/* Image Section */}
                        <section id="image-section-2">
                            <div className="container-fluid px-xl-5">
                                <div
                                    className="image-section"
                                    style={{backgroundImage: 'url("img/banner3.jpg")'}}
                                />
                            </div>
                        </section>
                        {/* Image Section End */}
                        {/* Feedbacks section */}
                        {
                            homeData?.feedbacks?.length ? (
                                <Container fluid>
                                    <Row className="px-xl-5">
                                        <Col sm={12}>
                                            <h3>
                                                Happy Customers
                                            </h3>
                                        </Col>
                                        <Col sm={12}>
                                            <Carousel
                                                showIndicators={false}
                                                showStatus={false}
                                                swipeable={true}
                                                autoPlay={false}
                                                className={'feedbacks'}
                                            >
                                                {
                                                    homeData.feedbacks.map((feedback, index) => {
                                                        return (
                                                            <div className="feedback-wrapper">
                                                                <p>{feedback.review}</p>
                                                                <div className="rating" dangerouslySetInnerHTML={{__html: renderStars(feedback.rating)}}></div>
                                                                <h5><strong>{feedback.user.name}</strong></h5>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </Col>
                                    </Row>
                                </Container>
                            ) : ''
                        }
                        {/* Feedbacks section End */}
                        {/* Everyday essentials */}
                        <Container fluid>
                            <Row className="px-xl-5">
                                <Col sm={6}>
                                    <h3 className="underline-heading">
                                        <span>Everyday</span> Essentials
                                    </h3>
                                </Col>
                                <Col sm={6} className="text-right">
                                    <a href="" className="section-links">
                                        View all
                                    </a>
                                </Col>
                            </Row>
                            <Row className="px-xl-5 my-4">
                                {
                                    typeof homeData?.everyday_products !== 'undefined' ? (
                                        homeData?.everyday_products.map((product, index) => {
                                            return (
                                                <Col sm={12} lg={3} key={index}>
                                                    <RegularProduct product={product}/>
                                                </Col>
                                            )
                                        })
                                    ) : (
                                        <Col sm={12} className="text-center">
                                            <h5 className="text-dark">No Products</h5>
                                        </Col>
                                    )
                                }

                            </Row>
                        </Container>
                        {/* Everyday essentials Ends */}
                        {/* Image Section */}
                        <section id="image-section-3">
                            <div className="container-fluid px-xl-5">
                                <div
                                    className="image-section"
                                    style={{backgroundImage: 'url("img/banner4.jpg")'}}
                                />
                            </div>
                        </section>
                        {/* Image Section End */}
                        {/* Services section */}
                        <div className="container-fluid">
                            <div className="row px-xl-5">
                                <div className="col-lg-3 col-sm-12">
                                    <div className="service-section">
                                        <div className="img">
                                            <img src={window.location.origin + "/img/deliver-car.png"} alt=""/>
                                        </div>
                                        <h5>Free Standard Delivery</h5>
                                        <p>Designated Day Delivery</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="service-section">
                                        <div className="img">
                                            <img src={window.location.origin + "/img/open-box.png"} alt=""/>
                                        </div>
                                        <h5>Freshly Prepared Ingredients</h5>
                                        <p>Made For Your Delivery Date</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="service-section">
                                        <div className="img">
                                            <img src={window.location.origin + "/img/check.png"} alt=""/>
                                        </div>
                                        <h5>Verified Brands</h5>
                                        <p>Guaranteed Products</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-12">
                                    <div className="service-section">
                                        <div className="img">
                                            <img src={window.location.origin + "/img/loading.png"} alt=""/>
                                        </div>
                                        <h5>98% of Happy Clients</h5>
                                        <p>Reach Their Personal Goals Sets</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Services section Ends */}
                    </div>
                )
            }
        </>
    )
};

export default Home;