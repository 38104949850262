import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Filter from "../components/Filters";
import {getProducts} from "../requests";
import Loader from "../components/Loader";
import RegularProduct from "../components/RegularProduct";
import ReactPaginate from "react-paginate";
import {Link} from "react-router-dom";
// import {filterItems} from "../helpers/productsHelper";
// import {useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import Select from "react-select";

const Products = () => {
    const filteredProducts = 'All Products';
    const [totalProducts, setTotalProducts] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [products, setProducts] = useState([]);
    const [loader, setLoader] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [filters, setFilters] = useState({});
    const searchParams = useSearchParams();
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(100);
    const [orderBy, setOrderBy] = useState({value: '', label: 'Relevance'});

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1);
    };

    // let selectedFilters = [];
    // const handleFilters = (type, id) => {
    //     if (selectedFilters.length) {
    //         selectedFilters.map((filter, index) => {
    //             if (type === filter.type) {
    //                 selectedFilters[index]['value'] = id;
    //             } else {
    //                 selectedFilters.push({type: type, value: id});
    //             }
    //         })
    //     } else {
    //         selectedFilters.push({type: type, value: id});
    //     }
    //
    //     let filtered_items = filterItems(products, selectedFilters);
    //     setProducts(filtered_items);
    //     setTotalProducts(filtered_items.length);
    // };

    const getProductsResponse = (orderBy) => {
        let limit = 12;
        let query = searchParams.get('q');
        setLoader(true);
        getProducts(currentPage, query, '', '', '', orderBy).then((res) => {
            setProducts(res.data.data.products);
            setTotalProducts(res.data.data.total_products);
            setTotalPages(Math.ceil(res.data.data.total_products/limit).toFixed(0));
            setCategories(res.data.data.categories);
            setBrands(res.data.data.brands);
            setMinPrice(res.data.data.minPrice);
            setMaxPrice(res.data.data.maxPrice);
            setLoader(false);
        });
    }

    useEffect(() => {
        if (products.length === 0) {
            getProductsResponse('')
        }
    });

    return (
        <>
        {
            loader ? (
                <Loader/>
            ) : (
                <div className="main-content">
                    {/* Shop Starts */}
                    <Container fluid>
                        <div className="breadcrumbs">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </li>
                                <li>Shop</li>
                            </ul>
                        </div>
                        <Row className="px-xl-5">
                            <Col lg={3} className="d-none d-lg-block">
                                <Filter
                                    categories={categories}
                                    brands={brands}
                                    filters={filters}
                                    setFilters={setFilters}
                                    currentPage={currentPage}
                                    setLoader={setLoader}
                                    setProducts={setProducts}
                                    setTotalProducts={setTotalProducts}
                                    setTotalPages={setTotalPages}
                                    setCategories={setCategories}
                                    setBrands={setBrands}
                                    minPrice={minPrice}
                                    maxPrice={maxPrice}
                                    setMinPrice={setMinPrice}
                                    setMaxPrice={setMaxPrice}
                                />
                            </Col>
                            <Col lg={9} sm={12} >
                                <div className="products-listing">
                                    <Row>
                                        <Col sm={9}>
                                            <h4>{filteredProducts}</h4>
                                            <p>{totalProducts} items</p>
                                        </Col>
                                        <Col sm={3}>
                                            <Select
                                                options={[
                                                    { value: '', label: 'Relevance' },
                                                    { value: 'asc', label: 'Low to High Price' },
                                                    { value: 'desc', label: 'High to Low Price' }
                                                ]}
                                                className="w-100 text-left"
                                                defaultValue={orderBy}
                                                onChange={(e) => {
                                                    setOrderBy(e)
                                                    getProductsResponse(e.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        {
                                            typeof products !== 'undefined' || products.length === 0 ? (
                                                products.map((product, index) => {
                                                    return (
                                                        <Col lg={4} md={6} sm={12} key={index}>
                                                            <RegularProduct product={product}/>
                                                        </Col>
                                                    )
                                                })
                                            ) : (
                                                <Col sm={12} className="text-center mt-5">
                                                    No Products Found
                                                </Col>
                                            )
                                        }
                                    </Row>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={1}
                                        pageCount={totalPages}
                                        previousLabel="<"
                                        renderOnZeroPageCount={null}
                                        className="react-pagination"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* Shop Ends */}
                </div>
            )
        }
        </>
    )
};

export default Products;